<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">
                        {{ $route.path.includes('addIncapacidades') ? 'Crear Incapacidad' : 'Editar Incapacidad' }}
                    </h3>
                    <form @submit.prevent="addIncapacidades">
                        <div class="form-group">
                            <label>Colaborador</label>
                            <select class="form-control" v-model="incapacidad.colaborador">
                                <option v-for="colaborador in colaboradores" :key="colaborador.id"
                                    :value="colaborador.id" v-text="colaborador.nombre">
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Desde</label>
                            <input type="date" class="form-control" v-model="incapacidad.desde" />
                        </div>
                        <div class="form-group">
                            <label>Hasta</label>
                            <input type="date" class="form-control" v-model="incapacidad.hasta" />
                        </div>
                        <div class="form-group">
                            <label>Motivo</label>
                            <input type="text" class="form-control" v-model="incapacidad.motivo" />
                        </div>
                        <div class="form-group">
                            <label>Comentarios</label>
                            <textarea type="text" class="form-control" v-model="incapacidad.comentarios"
                                rows="3"></textarea>
                        </div>
                        <div v-if="$route.path.includes('addIncapacidades')" class="form-group">
                            <label>Archivo</label>
                            <input type="file" class="form-control" @change="obtenerDocumento" />
                        </div>
                        <button type="submit" class="btn btn-success">
                            Guarda
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            incapacidades: [],
            colaboradores: [],
            incapacidad: {},
            documento: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
        };
    },
    created() {
        if (this.$route.path.includes('addIncapacidades')) {
            this.axios
                .get(`/api/incapacidades/${this.$route.params.id}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    this.incapacidades = response.data.incapacidades;
                    this.colaboradores = response.data.colaboradores;
                });
        } else {
            this.axios
            .get(`/api/incapacidades/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.incapacidad = response.data.incapacidad;
                this.colaboradores = response.data.colaboradores;
            });
        }
    },
    methods: {
        obtenerDocumento(event) {
            let file = event.target.files[0];
            this.documento.archivo = file;
        },
        addIncapacidades() {

            if (this.$route.path.includes('addIncapacidades')) {
                let formData = new FormData();
                formData.append("archivo", this.documento.archivo);
                formData.append("colaborador", this.incapacidad.colaborador);
                formData.append("desde", this.incapacidad.desde);
                formData.append("hasta", this.incapacidad.hasta);
                formData.append("motivo", this.incapacidad.motivo);
                formData.append("comentarios", this.incapacidad.comentarios);
                this.axios
                    .post("/api/incapacidades", formData, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    })
                    .then((response) => location.reload())
                    .catch((err) => console.log(err))
                    .finally(() => (this.loading = false));
                Swal.fire({
                    icon: "success",
                    title: "Incapacidad creada!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                this.axios
                    .patch(
                        `/api/incapacidades/${this.$route.params.id}`,
                        this.incapacidad, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                    )
                    .then((res) => {
                        this.$router.go(0);
                    });
                Swal.fire({
                    icon: "success",
                    title: "Datos de incapacidad actualizados!",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        },
    },
};
</script>
