<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div
                            class="col col-sm-3 col-xs-10"
                            style="padding-right: 0px; max-width: 5%"
                        >
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                        <div
                            class="col-sm-9 col-xs-12 text-center"
                            style="margin-left: 9%"
                            v-if="listLength != 0"
                        >
                            <label id="reportes">Presupuesto</label>
                            <select id="presupuesto">
                                <option
                                    id="datos"
                                    v-for="presupuesto in presupuestos"
                                    :key="presupuesto.id"
                                    :value="presupuesto.id"
                                    v-text="presupuesto.nombre"
                                >
                                </option>
                            </select>
                            <label style="margin-left: 15px"
                                >Fecha Inicial</label
                            >
                            <input type="date" id="fecha_Inicio" />
                            <label style="margin-left: 15px">Fecha Final</label>
                            <input type="date" id="fecha_Fin" />
                            <button
                                id="agregar1"
                                class="btn btn-info"
                                @click="buscar()"
                            >
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <hr />
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th>Número de Documento</th>
                                <th>Proveedor</th>
                                <th>Fecha</th>
                                <th>Monto</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td>{{ reporteL.numero }}</td>
                                <td>
                                    {{ cargarProveedor(reporteL.proveedor) }}
                                </td>
                                <td>{{ reporteL.fecha }}</td>
                                <td>₡{{ reporteL.monto }}</td>
                                <td>{{ reporteL.estado }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            egresos: [],
            presupuestos: [],
            proveedores: [],
            colillas: [],
            presupuesto: {},
            datos: {},
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/liquidacion`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.egresos = response.data.egresos;
            this.presupuestos = response.data.presupuestos;
            this.proveedores = response.data.proveedores;
            this.colillas = response.data.colillas;
            this.listLength = this.datos.length;
        });
    },
    methods: {
        buscar() {
            var presupuesto = document.getElementById("presupuesto").value;
            var fecha_Inicio = document.getElementById("fecha_Inicio").value;
            var fecha_Fin = document.getElementById("fecha_Fin").value;
            var aux = new Array();
            for (var key in this.egresos) {
                if (this.egresos[key].presupuesto == presupuesto) {
                    if (
                        this.egresos[key].created_at >= fecha_Inicio &&
                        this.egresos[key].created_at <= fecha_Fin
                    ) {
                        var aux2 = {
                            numero: "",
                            proveedor: "",
                            fecha: "",
                            monto: "",
                            estado: "",
                        };
                        aux2.numero = this.egresos[key].numero_documento;
                        aux2.proveedor = this.egresos[key].proveedor;
                        var fecha = new Date(this.egresos[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.monto = this.egresos[key].monto;
                        aux2.estado = this.egresos[key].estado;
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.colillas) {
                if (this.colillas[key].presupuesto == presupuesto) {
                    if (
                        this.colillas[key].created_at >= fecha_Inicio &&
                        this.colillas[key].created_at <= fecha_Fin
                    ) {
                        var aux1 = {
                            numero: "",
                            proveedor: "",
                            fecha: "",
                            monto: "",
                            estado: "",
                        };
                        aux1.numero = this.colillas[key].numero_Factura;
                        var fecha = new Date(this.colillas[key].created_at);
                        aux1.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux1.proveedor = null;
                        aux1.estado = this.colillas[key].estado;
                        aux1.monto = this.colillas[key].neto;
                        aux.push(aux1);
                    }
                }
            }
            this.datos = aux;
        },
        cargarProveedor(id) {
            var nombres = "";
            for (var key in this.proveedores) {
                for (var i in this.proveedores[key]) {
                    if (this.proveedores[key].id == id) {
                        nombres = this.proveedores[key].nombre;
                    }
                    if (id == null) {
                        nombres = "Asociación Mariano Juvenil";
                    }
                }
            }
            return nombres;
        },
    },
};
</script>
