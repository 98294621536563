var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-offset-1 col-md-12 px-0"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 text-start"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.userRole === 'Administrador' ||
                _vm.userRole === 'Nivel1' ||
                _vm.userRole === 'SuperAdministrador'
              ),expression:"\n                userRole === 'Administrador' ||\n                userRole === 'Nivel1' ||\n                userRole === 'SuperAdministrador'\n              "}]},[_c('div',{staticClass:"btn_group"},[_c('router-link',{staticClass:"btn btn-info pull-left",attrs:{"to":"/addTipos","id":"agregar"}},[_vm._v("Nuevo "),_c('i',{staticClass:"fas fa-plus"})])],1)])]),_vm._m(0)])]),(_vm.listLength == 0)?_c('NoExist'):_vm._e(),(_vm.listLength != 0)?_c('div',{staticClass:"panel-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-condensed"},[_vm._m(1),_c('tbody',_vm._l((_vm.datos),function(tipo){return _c('tr',{key:tipo.id},[_c('td',[_vm._v(_vm._s(tipo.nombre))]),_c('td',[_vm._v(_vm._s(tipo.puntos))]),_c('td',[_vm._v(_vm._s(tipo.tipo))]),_c('td',[_vm._v(_vm._s(tipo.categoria))]),_c('td',[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{ name: 'editTipos', params: { id: tipo.id } }}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteTipos(tipo.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1)])])}),0)]),(_vm.listLength > 10)?_c('Pagination',{attrs:{"listLength":_vm.listLength,"paginaActual":_vm.paginaActual,"getNextPage":_vm.getNextPage,"totalPaginas":_vm.totalPaginas,"getPreviousPage":_vm.getPreviousPage,"isActive":_vm.isActive,"getDataPagina":_vm.getDataPagina}}):_vm._e()],1):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-9"},[_c('h3',{staticClass:"title"},[_vm._v("Tipos de Eventos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Puntos")]),_c('th',[_vm._v("Tipo")]),_c('th',[_vm._v("Categoria")]),_c('th')])])
}]

export { render, staticRenderFns }