<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/egresos" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Egresos</h3>
                    <form @submit.prevent="addEgreso">
                        <div class="row">
                            <div class="form-group1">
                                <label>Proveedor</label>
                                <select class="form-control" v-model="egreso.proveedor">
                                    <option v-for="pro in proveedores" :key="pro.id" :value="pro.id"
                                        v-text="pro.nombre">
                                    </option>
                                </select>
                            </div>
                            <button type="button" id="abrir" class="btn btn-primary" data-toggle="modal"
                                data-target="#exampleModal">
                                <i class="fas fa-plus"></i>
                            </button>

                            <!-- Modal -->
                            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLabel">
                                                Modal title
                                            </h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <form @submit.prevent="addProveedor">
                                                <div class="form-group">
                                                    <label>Nombre</label>
                                                    <input type="text" class="form-control" v-model="proveedor.nombre
                                                        " />
                                                </div>
                                                <div class="form-group">
                                                    <label>Razón Social</label>
                                                    <input type="text" class="form-control" v-model="proveedor.razon_social
                                                        " />
                                                </div>

                                                <div class="form-group">
                                                    <label>Plazo</label>
                                                    <input type="text" class="form-control" v-model="proveedor.plazo
                                                        " />
                                                </div>
                                                <div class="form-group">
                                                    <label>Teléfono</label>
                                                    <input type="text" class="form-control" v-model="proveedor.contacto_Telefonico
                                                        " />
                                                </div>
                                                <div class="form-group">
                                                    <label>Correo</label>
                                                    <input id="email" type="email" class="form-control" v-model="proveedor.contacto_Correo
                                                        " />
                                                </div>
                                                <div class="form-group">
                                                    <label>Cuenta</label>
                                                    <input type="text" class="form-control" v-model="proveedor.formas_pago_Cuenta
                                                        " />
                                                </div>
                                                <button type="submit" class="btn btn-success">
                                                    Agregar
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group1">
                                <label>Presupuesto</label>
                                <select id="presupuesto" class="form-control" v-model="egreso.presupuesto"
                                    @change="cargarRubros()">
                                    <option id="datos" v-for="presupuesto in presupuestos" :key="presupuesto.id"
                                        :value="presupuesto.id" v-text="presupuesto.nombre" @click="
                                            cargarMontoPresupuesto(
                                                presupuesto.id
                                            )
                                            ">
                                        {{ presupuesto.nombre }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group1">
                                <label> Presupuesto Disponible</label>
                                <input placeholder="₡" type="text" class="form-control" id="monto" name="monto"
                                    disabled />
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="form-group1">
                                <label>Rubro</label>
                                <select id="rubros" class="form-control" v-model="egreso.rubro_Presupuesto"
                                    @click="cargarMontoRubro()"></select>
                            </div>
                            <div class="form-group1">
                                <label>Monto Disponible de Rubro</label>
                                <input placeholder="₡" type="text" class="form-control" id="montoRubro"
                                    name="montoRubro" disabled />
                            </div>

                            <div class="form-group1">
                                <label>Tipo</label>
                                <select class="form-control" v-model="egreso.tipo">
                                    <option value="Credito">Crédito</option>
                                    <option value="Contado">Contado</option>
                                </select>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="form-group1">
                                <label>Número de Factura</label>
                                <input type="text" class="form-control" v-model="egreso.numero_documento" />
                            </div>
                            <div class="form-group1">
                                <label>Fecha del Documento</label>
                                <input type="date" class="form-control" v-model="egreso.fecha_documento" />
                            </div>
                            <div class="form-group1">
                                <label>Fecha de Vencimiento</label>
                                <input type="date" class="form-control" v-model="egreso.fecha_vencimiento" />
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="form-group1">
                                <label>Estado</label>
                                <select class="form-control" v-model="egreso.estado">
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="Pagado">Pagado</option>
                                    <option value="Anulado">Anulado</option>
                                </select>
                            </div>

                            <div class="form-group1">
                                <label>Monto</label>
                                <input placeholder="₡" type="text" id="number" class="form-control"
                                    v-model="egreso.monto" />
                            </div>
                            <div class="form-group1">
                                <label>Detalle</label>
                                <textarea type="text" id="number" class="form-control" v-model="egreso.detalle">
                                </textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group1">
                                <label>Número de Transferencia</label>
                                <input type="text" class="form-control" v-model="egreso.numero_transferencia" />
                            </div>
                        </div>
                        <button type="submit" class="btn btn-success align-items-center">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            egresos: [],
            egreso: {},
            rubros: [],
            rubro: {},
            presupuestos: [],
            proveedores: [],
            presupuesto: {},
            proveedor: {},
            pageSize: 15,
            datos: [],
            paginaActual: 1,
        };
    },
    created() {
        this.axios.get("/api/egresos", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.rubros = response.data.rubros;
            this.presupuestos = response.data.presupuestos;
            this.proveedores = response.data.proveedores;
            this.cargar();
        });
    },
    methods: {
        cargar() {
            $(function () {
                $("#presupuesto").change(function () {
                    sessionStorage.setItem("presupuesto", this.value);
                });
                if (sessionStorage.getItem("presupuesto")) {
                    $("#presupuesto").val(sessionStorage.getItem("presupuesto"));
                }
            });
        },
        addEgreso() {
            this.axios.post("/api/egresos", this.egreso, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                var resultado = response.data.resultado["resultado"];
                var x = parseInt(resultado[0]) === parseInt(1);
                if (x) {
                    Swal.fire({
                        title: "No se puede Crear",
                        text: "El rubro no cuenta con el monto suficiente",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Egreso Creado!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.reload();
                }
            });
        },
        addProveedor() {
            this.axios
                .post("/api/proveedores", this.proveedor, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Proveedor Creado!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();
                    }
                });
        },
        cargarMontoPresupuesto(id) {
            var nombres = "";
            for (var key in this.presupuestos) {
                for (var i in this.presupuestos[key]) {
                    if (this.presupuestos[key].id == id) {
                        nombres = this.presupuestos[key].monto;
                    }
                }
            }
            return $("#monto").val(nombres);
        },
        cargarMontoRubro() {
            var id = document.getElementById("rubros");
            var nombres = "";
            for (var key in this.rubros) {
                for (var i in this.rubros[key]) {
                    if (this.rubros[key].id == id.value) {
                        nombres = this.rubros[key].monto_rubro;
                    }
                }
            }
            return $("#montoRubro").val(nombres);
        },
        cargarRubros() {
            this.cargarMontoPresupuesto(sessionStorage.getItem("presupuesto"));
            this.cargarMontoRubro();
            var presupue = document.getElementById("presupuesto");
            var rubr = document.getElementById("rubros");
            $("#rubros").empty();
            var presupuestoSeleccionado = presupue.value;
            var lista_rubros = this.rubros;
            lista_rubros.sort();
            lista_rubros.forEach((element) => {
                if (element.ID_Presupuesto == presupuestoSeleccionado) {
                    var opcion = document.createElement("option");
                    opcion.value = element.id;
                    opcion.text = element.nombre_rubro;
                    rubr.add(opcion);
                }
            });
        },
    },
};
</script>
