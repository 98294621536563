
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Epicrisis</h3>
      <form enctype="multipart/form-data" @submit.prevent="addEpicrisis">
      <div class="form-group">
        <label>Fecha</label>
        <input
          type="date"
          class="form-control"
          v-model="epicrisis.fecha"
          />
      </div>
      <div class="form-group">
        <label>Detalle</label>
        <input
          type="text"
          class="form-control"
          v-model="epicrisis.detalle"
          />
      </div>
      <div class="form-group">
        <label>Documento</label>
        <input
          type="file"
          class="form-control"
          @change="obtenerDocumento"
          />
      </div>
      <button type="submit" class="btn btn-success">Agregar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  data() {
    return {
      epicrisis: [],
      joven:{},
      epicrisi:{'joven': ''},
      documento:{
        nombre:'',
        stock: 0,
        archivo :'',
      }
    }
  },
  created() {
      this.axios
      .get(`/api/epicrisis/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.epicrisis = response.data.epicrisis;
      this.joven = response.data.joven;
    });
  },
   methods: {
    obtenerDocumento(event){
       let file = event.target.files[0];
       this.documento.archivo = file;
    },
    addEpicrisis() {
      let formData = new FormData();
      formData.append('documento',this.documento.archivo);
      formData.append('fecha',this.epicrisis.fecha);
      formData.append('detalle',this.epicrisis.detalle);
      formData.append('joven',this.joven.id);
      this.axios
        .post("/api/epicrisis", formData, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => location.reload())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
        Swal.fire({
        icon: 'success',
        title: 'Epicrisis Creada!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>