<template>
  <div class="container">
    <div class="row px-3">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"
            ><i class="fas fa-arrow-alt-circle-left"></i
          ></a>
          <h3 class="title text-center mt-4">Tipos de Eventos</h3>
          <form @submit.prevent="addCita">
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" class="form-control" v-model="tipo.nombre" />
            </div>
            <div class="form-group">
              <label>Puntos</label>
              <input type="number" class="form-control" v-model="tipo.puntos" />
            </div>
            <div class="form-group">
              <label>Tipo</label>
              <select class="form-control" v-model="tipo.tipo">
                <option value="Normativa">Normativa</option>
                <option value="Control">Control</option>
              </select>
            </div>
            <div class="form-group">
              <label>Categoria</label>
              <select class="form-control" v-model="tipo.categoria">
                <option value="Jóvenes">Jóvenes</option>
                <option value="Colaboradores">Colaboradores</option>
              </select>
            </div>
            <button type="submit" class="btn btn-success">Agregar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipos: [],
      tipo: {},
    };
  },
  created() {
    this.axios
      .get(`/api/tipos/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.tipos = response.data;
      });
  },
  methods: {
    addCita() {
      this.axios
        .post("/api/tipos", this.tipo, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => location.reload())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      Swal.fire({
        icon: "success",
        title: "Tipo de Evento Creado!",
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
};
</script>
