<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-1 col-xs-12">
                            <router-link to="/asociados" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
                        </div>
                        <div class="col col-sm-6 col-xs-12">
                            <h4 class="title">Documentos del asociado</h4>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table  id="recibos" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Documento</th>
                            <th>Nombre documento</th>
                            <th>Fecha de registro</th>
                            <th>Fecha de actualización</th>
                            <th>Descargar</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="recibo in recibos" :key="recibo.id">
                        <td>{{ recibo.id }}</td>
                        <td>{{ recibo.nombreRecibo }}</td>
                        <td> {{ format_date(recibo.created_at) }}</td>
                        <td>{{ format_date(recibo.updated_at)}}</td>

                        <td>
                          <div class="btn-group" role="group">
                              <button class="btn btn-success" v-on:click="descargar(recibo.id)">
                                <i class="fas fa-file-download"></i>
                              </button>
                          </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import NoExist from "../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      recibos: [],
      pageSize:5,
      datos:[],
      paginaActual:1,
      listLength:0
    };
  },
  created() {
    this.axios.get(`/api/recibo-asociado/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.recibos = response.data;
        this.listLength = this.recibos.length;
        this.getDataPagina(1);
    });
  },
   methods: {
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM/DD/YYYY hh:mm:ss')
          }
      },
    async descargar(id) {
      try {
        const response = await this.axios.get(`/api/recibo-asociado/${id}/pdf`, {
          responseType: 'blob',  // Para manejar la respuesta como un archivo Blob
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.target = '_blank';
        fileLink.click();
      } catch (error) {
        console.error('Error al generar el recibo:', error);
      }
    },
    totalPaginas(){
       return Math.ceil(this.recibos.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.recibos.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     }
  },
};
</script>