
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Estado Nutricional</h3>
      <form @submit.prevent="updateNutricion">
      <div class="form-group">
        <label>Fecha</label>
        <input
          type="date"
          class="form-control"
          v-model="nutricion.fecha"
          />
      </div>
      <div class="form-group">
        <label>Peso</label>
        <input
          type="text"
          class="form-control"
          v-model="nutricion.peso"
        />
      </div>
      <div class="form-group">
        <label>Talla</label>
        <input
          type="text"
          class="form-control"
          v-model="nutricion.talla"
        />
      </div>
      <div class="form-group">
        <label>Estatura</label>
        <input
          type="text"
          class="form-control"
          v-model="nutricion.estatura"
        />
      </div>
      <button  type="submit" class="btn btn-success">Editar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      nutricion:{},
    };
  },
  created() {
      this.axios
      .get(`/api/nutriciones/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.nutricion = response.data.nutricion;
    });
  },
   methods: {
    updateNutricion() {
      this.axios
        .patch(
          `/api/nutriciones/${this.$route.params.id}`,
          this.nutricion, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((res) => {
          this.$router.go(0);
        });
        Swal.fire({
        icon: 'success',
        title: 'Estado Nutricional Actualizado!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>