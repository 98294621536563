<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10" style="padding-right: 0px;">
                            <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
                            <h3 class="title">Personal Acompañante</h3>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                               <router-link :to="{ name: 'addPersonales', params: { id: joven.id } }" id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Agregar <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table class="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                            <th>Fecha</th>
                            <th>Cita</th>
                            <th>Acompañante</th>
                            <th>Detalle</th>
                            <th>Estado</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="personal in datos" :key="personal.id">
                        <td>{{ personal.fecha }}</td>
                        <td>{{ cargarCita(personal.cita)}}</td>
                        <td>{{ cargarUsuario(personal.personal) }}</td>
                        <td>{{ personal.detalle}}</td>
                        <td>{{ personal.estado}}</td>
                        <td>
                        <div class="btn-group" role="group">
                            <router-link
                            :to="{ name: 'editPersonales', params: { id: personal.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deletePersonal(personal.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"><</a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
   inject:['reload'],
  data() {
    return {
      personales: [],
      joven:[],
      citas:[],
      usuarios:[],
      pageSize:10,
      datos:[],
      paginaActual:1,
    };
  },
  created() {
      this.axios
        .get(`/api/personales/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
        this.personales = response.data.personales;
        this.joven = response.data.joven;
        this.citas = response.data.citas;
        this.usuarios = response.data.usuarios;
        this.getDataPagina(1);
    });
  },
   methods: {
    deletePersonal(id) {
      this.axios
        .delete(`/api/personales/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          let i = this.personales.map((data) => data.id).indexOf(id);
          this.personales.splice(i, 1);
          this.reload();
        });
        Swal.fire({
            title: 'Eliminado!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
        })
    },
    totalPaginas(){
        return Math.ceil(this.personales.length / this.pageSize)

     },
     getDataPagina(noPagina){

            this.paginaActual = noPagina;
            this.datos=[];
            let x = noPagina-1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.personales.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     },
     cargarCita(id){
       var especialistas = '';
       for(var key in this.citas){
         for(var i in this.citas[key]){
          if(this.citas[key].id == id){
            especialistas = this.citas[key].especialista;
          }
         }
       }
       return especialistas;
     }
     ,
     cargarUsuario(id){
       var nombres = '';
       for(var key in this.usuarios){
         for(var i in this.usuarios[key]){
          if(this.usuarios[key].id == id){
            nombres = this.usuarios[key].name;
          }
         }
       }
       return nombres;
     }
  },
};
</script>