<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div
                            class="col col-sm-3 col-xs-10"
                            style="padding-right: 0px"
                        >
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                            <h3 class="title">Pertenencias</h3>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link
                                    :to="{
                                        name: 'addPertenencias',
                                        params: { id: joven.id },
                                    }"
                                    id="agregar"
                                    class="btn btn-info pull-left"
                                    style="margin-top: -7px; margin-left: 2px"
                                    >Agregar <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Artículo</th>
                                <th class="text-center">Detalle</th>
                                <th class="text-center">Archivo</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="pertenencia in datos"
                                :key="pertenencia.id"
                            >
                                <td class="text-center">
                                    {{ pertenencia.pertenencia }}
                                </td>
                                <td class="text-center">
                                    {{ pertenencia.detalle }}
                                </td>
                                <td class="text-center">
                                    <a
                                        target="_blank"
                                        class="pdf"
                                        :href="`${filebaseurl}/${pertenencia.foto}`"
                                        alt=""
                                        >Foto</a
                                    >
                                </td>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <router-link
                                            :to="{
                                                name: 'editPertenencias',
                                                params: { id: pertenencia.id },
                                            }"
                                            class="btn btn-success"
                                            ><i class="fas fa-edit"></i
                                        ></router-link>
                                        <button
                                            class="btn btn-danger"
                                            @click="
                                                deletePertenencia(
                                                    pertenencia.id
                                                )
                                            "
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li
                                class="page-item"
                                v-on:click="getPreviousPage()"
                            >
                                <a class="page-link" href="#"></a>
                            </li>
                            <li
                                v-for="pagina in totalPaginas()"
                                v-on:click="getDataPagina(pagina)"
                                class="page-item"
                                v-bind:key="isActive(pagina)"
                            >
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            filebaseurl:process.env.VUE_APP_FILES_BASE_URL,
            pertenencias: [],
            joven: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
        };
    },
    created() {
        this.axios
            .get(`/api/pertenencias/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.pertenencias = response.data.pertenencias;
                this.joven = response.data.joven;
                this.getDataPagina(1);
            });
    },
    methods: {
        deletePertenencia(id) {
            this.axios.delete(`/api/pertenencias/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                let i = this.pertenencias.map((data) => data.id).indexOf(id);
                this.pertenencias.splice(i, 1);
                this.reload();
            });
            Swal.fire({
                title: "Eliminado!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.pertenencias.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.pertenencias.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
