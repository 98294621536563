
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Tipos de Eventos</h3>
      <form @submit.prevent="updateTipos">
      <div class="form-group">
        <label>Nombre</label>
        <input
          type="text"
          class="form-control"
          v-model="tipo.nombre"
          />
      </div>
      <div class="form-group">
        <label>Puntos</label>
        <input
          type="number"
          class="form-control"
          v-model="tipo.puntos"
        />
      </div>
      <div class="form-group">
        <label>Tipo</label>
        <select class="form-control" v-model="tipo.tipo">
          <option value="Normativa">Normativa</option>
          <option value="Control">Control</option>
          <option value="Colaborador">Colaborador</option>
        </select>
      </div>
      <div class="form-group">
        <label>Categoria</label>
        <select class="form-control" v-model="tipo.categoria">
          <option value="Jóvenes">Jóvenes</option>
          <option value="Colaboradores">Colaboradores</option>
        </select>
      </div>
      <button  type="submit" class="btn btn-success">Editar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      tipo:{},
    };
  },
  created() {
      this.axios
      .get(`/api/tipos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.tipo = response.data;
    });
  },
   methods: {
    updateTipos() {
      this.axios
        .patch(
          `/api/tipos/${this.$route.params.id}`,
          this.tipo, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((res) => {
          this.$router.go(0);
        });
        Swal.fire({
        icon: 'success',
        title: 'Tipo de Evento Actualizado!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>