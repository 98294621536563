<template>
  <div class="container">
    <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <router-link to="/colillas" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
        <h3 class="title text-center mt-4">Colillas</h3>
          <form @submit.prevent="updateColilla">
            <div class="row">
        <div class="form-group1">
            <label>Colaborador</label>
            <input
            type="text"
            id="colaborador"
            name="colaborador"
            class="form-control"
            disabled
          />
          </div>
          <div class="form-group1">
            <label>Presupuesto</label>
            <input
            type="text"
            id="presupuesto"
            name="presupuesto"
            class="form-control"
            disabled
          />
          </div>
      <div class="form-group1">
        <label>Número Factura</label>
        <input
          type="text"
          class="form-control"
          v-model="colilla.consecutivo"
          />
      </div>

      </div>
      <hr>
      <div class="row">
        <div class="form-group1">
        <label>Salario</label>
        <input
        type="text"
        placeholder="₡"
          class="form-control"
          v-model="colilla.salario"
        />
      </div>
        <div class="form-group1">
        <label>Periodo</label>
        <input
          type="text"
          class="form-control"
          v-model="colilla.periodo"
          />
      </div>
      <div class="form-group1">
        <label>Horas Extra</label>
        <input
        type="text"
          class="form-control"
          v-model="colilla.horasExtra"
        />
      </div>

      </div>
      <hr>

      <div class="row">
        <div class="form-group1">
        <label>CCSS</label>
        <input
        type="text"
        placeholder="₡"
          class="form-control"
          v-model="colilla.ccss"
        />
      </div>
        <div class="form-group1">
        <label>Feriados</label>
        <input
          type="number"
          class="form-control"
          v-model="colilla.feriados"
          />
      </div>
        <div class="form-group1">
        <label>Otros</label>
        <input
        type="text"
          class="form-control"
          v-model="colilla.otros"
        />
      </div>
      </div>
      <hr>
      <div class="row">
      <div class="form-group1">
        <label>Total</label>
        <input
        type="text"
        placeholder="₡"
          class="form-control"
          v-model="colilla.neto"
        />
      </div>
      </div>
            <button type="submit" class="btn btn-success">Editar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     colilla: {},
     colaborador:{},
     presupuestos:{}
    };
  },
  created() {
    this.axios
      .get(`/api/colillas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((res) => {
        this.colilla = res.data.colillas;
        this.colaboradores = res.data.colaboradores;
        this.presupuestos = res.data.presupuestos;
        this.cargarColaboradores(this.colilla.colaborador);
        this.cargarPresupuesto(this.colilla.presupuesto);
      });
  },
  methods: {
    updateColilla() {
      this.axios
        .patch(
          `/api/colillas/${this.$route.params.id}`,
          this.colilla, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((response) => {
            var resultado = response.data.resultado['resultado'];
            var x = parseInt(resultado[0])===parseInt(1);
            if(x){
                Swal.fire({
                title: 'Actualizada!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
                })
            }
            else{
                Swal.fire({
                title: 'No se puede Actualizar',
                text: 'El rubro no cuenta con el monto sufuciente',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500
                })
            }
             this.$router.push({ name: 'colillas' })
            });
    },
    cargarColaboradores(id){
       var nombres = '';
       for(var key in this.colaboradores){
         for(var i in this.colaboradores[key]){
          if(this.colaboradores[key].id == id){
            nombres = this.colaboradores[key].nombre;
          }
         }
       }
       return $("#colaborador").val(nombres);
    },
    cargarPresupuesto(id){
       var nombres = '';
       for(var key in this.presupuestos){
         for(var i in this.presupuestos[key]){
          if(this.presupuestos[key].id == id){
            nombres = this.presupuestos[key].nombre;
          }
         }
       }
       return $("#presupuesto").val(nombres);
    },
  },
};
</script>