<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/permisosadm" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Revision de permisos</h3>
                    <form @submit.prevent="updatePermisosAdm">
                        <div class="form-group">
                            <label>Usuario</label>
                            <input type="text" class="form-control" v-model="permiso.nombre" />
                        </div>
                        <div class="form-group">
                            <label>Desde</label>
                            <input type="text" class="form-control" v-model="permiso.desde" />
                        </div>
                        <div class="form-group">
                            <label>Hasta</label>
                            <input type="text" class="form-control" v-model="permiso.hasta" />
                        </div>
                        <div class="form-group">
                            <label>Comentarios del colaborador</label>
                            <textarea class="form-control" v-model="permiso.comentarios"></textarea>
                        </div>
                        <div class="row justify-content-center">
                            <div class="btn-group" role="group">
                                <button class="btn btn-success" style="margin-right: 5%" v-on:click="callFunction"
                                    data-id="Aprobado" value="Aprobado">
                                    Aprobar
                                </button>
                                <button class="btn btn-danger" v-on:click="callFunction" style="margin-left: 5%"
                                    data-id="Rechazado" value="Rechazado">
                                    Rechazar
                                </button>
                            </div>
                        </div>

                        <!-- <button type="submit" class="btn btn-success">Editar</button> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            permiso: {},
        };
    },
    created() {
        this.axios
            .get(`/api/permisosadm/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((res) => {
                this.permiso = res.data;
            });
    },
    methods: {
        updatePermisosAdm() {
            this.axios
                .patch(
                    `/api/permisosadm/${this.$route.params.id}`,
                    this.permiso, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                }
                )
                .then((response) => {
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    var y = parseInt(resultado[0]) === parseInt(3);
                    var z = parseInt(resultado[0]) === parseInt(4);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } if (y) {
                        Swal.fire({
                            title: "Permiso aprobado",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } if (z) {
                        Swal.fire({
                            title: "Permiso cancelado",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Datos Actualizados!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.reload();
                    }
                });
        },
        callFunction: function (event) {
            var id = event.target.getAttribute("data-id");
            this.permiso.estado = id;
            return permiso.estado;
        },
    },
};
</script>
