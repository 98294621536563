<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-4 col-xs-6">
                            <h4 class="title">Gestión de Aguinaldos</h4>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table id="colaboradores" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th class="text-center">Colaborador</th>
                                <th class="text-center">
                                    Monto de aguinaldo al periodo actual
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="recibo in datos" :key="recibo.id">
                                <td class="text-center">{{ recibo.nombre }}</td>
                                <td class="text-center">
                                    ₡{{ recibo.salario_bruto }}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <router-link :to="{
                                            name: 'showAguinaldos',
                                            params: { id: recibo.colaborador },
                                        }" class="btn btn-success">Ver detalle
                                            <i class="fa-solid fa-eye"></i>
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination></Pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
import Pagination from "../Shared/Pagination.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            recibos: [],
            pageSize: 5,
            datos: [],
            paginaActual: 1,
            listLength: 0,
        };
    },
    created() {
        this.axios.get("/api/aguinaldos", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.recibos = response.data;
            this.listLength = this.recibos.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deleteRecibo(id) {
            Swal.fire({
                title: "¿Desea continuar?",
                confirmButtonColor: "red",
                showCancelButton: true,
                confirmButtonText: "Delete",
                icon: "error",
            }).then((res) => {
                if (res.value) {
                    this.axios
                        .delete(`/api/recibocolaborador/${id}`, {
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                            }
                        }).then((response) => {
                            let i = this.recibos
                                .map((data) => data.id)
                                .indexOf(id);
                            this.recibos.splice(i, 1);
                            this.reload();
                        });
                }
            });
        },
        totalPaginas() {
            return Math.ceil(this.recibos.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.recibos.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
