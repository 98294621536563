<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10" style="padding-right: 0px;">
                            <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
                            <h3 class="title">Eventos De Colaborador</h3>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                              <router-link :to="{ name: 'addEventosColaboradores', params: { id: colaborador.id } }" id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Agregar <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table class="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                            <th>Tipo</th>
                            <th>Detalle</th>

                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="evento in datos" :key="evento.id">
                        <td>{{ cargarTipo(evento.tipo) }}</td>
                        <td>{{ evento.detalle}}</td>
                        <td>
                        <div class="btn-group" role="group">
                            <button
                            class="btn btn-danger"
                            @click="deleteEvento(evento.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
   inject:['reload'],
  data() {
    return {
      eventos: [],
      tipos: [],
      colaborador:[],
      pageSize:10,
      datos:[],
      paginaActual:1,
    };
  },
  created() {
      this.axios
        .get(`/api/eventoscolaboradores/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
        this.eventos = response.data.eventos;
        this.tipos = response.data.tipos;
        this.colaborador = response.data.colaborador;
        this.getDataPagina(1);
    });
  },
   methods: {
    deleteEvento(id) {
                    Swal.fire({
          title:'¿Desea continuar?',
          confirmButtonColor:"red",
          showCancelButton:true,
          confirmButtonText:"Delete",
          icon:"error"
    }).then(res =>{
      if (res.value) {
      this.axios
        .delete(`/api/eventoscolaboradores/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          let i = this.eventos.map((data) => data.id).indexOf(id);
          this.eventos.splice(i, 1);
          this.reload();
        });
      }
    });

    },
    cargarTipo(id){
       var nombres = '';
       for(var key in this.tipos){
         for(var i in this.tipos[key]){
          if(this.tipos[key].id == id){
            nombres = this.tipos[key].nombre;
          }
         }
       }
       return nombres;
     },
    totalPaginas(){
        return Math.ceil(this.eventos.length / this.pageSize)

     },
     getDataPagina(noPagina){

            this.paginaActual = noPagina;
            this.datos=[];
            let x = noPagina-1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.eventos.slice(ini,fin);


     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     }
  },
};
</script>
