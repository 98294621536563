<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-10" style="padding-right: 0px; max-width: 5%;">
                            <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-center" style="margin-left: 9%;" v-if="listLength != 0">
                            <label id="reportes" >Presupuesto</label>
                            <select  id='presupuesto' >
                            <option id="datos" v-for=" presupuesto in presupuestos" :key="presupuesto.id" :value="presupuesto.id" v-text="presupuesto.nombre">
                            {{presupuesto.nombre}}
                            </option>
                            </select>
                            <label style="margin-left: 15px;">Total</label>
                            <input
                            type="text"
                            id="total"
                            disabled
                            />
                            <label style="margin-left: 15px;">Disponible</label>
                            <input
                            type="text"
                            id="disponible"
                            disabled
                            />
                            <button id="agregar1" class="btn btn-info" @click="buscar()">Consultar <i class="fas fa-search"></i></button>
                        </div>
                        <hr>

                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0"/>
                    <table class="table table-bordered table-striped table-condensed" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Número de Documento</th>
                            <th>Fecha</th>
                            <th>Monto</th>
                            <th>Estado</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="reporteL in datos" :key="reporteL.id">
                        <td>{{ reporteL.numero }}</td>
                        <td>{{ reporteL.fecha}}</td>
                        <td>₡{{ reporteL.monto}}</td>
                        <td>{{ reporteL.estado}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      egresos: [],
      presupuestos:[],
      proveedores:[],
      colillas: [],
      presupuesto:{},
      datos:{},
      listLength:0
    };
  },
  created() {
      this.axios.get(`/api/reportes/estado`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
        this.egresos = response.data.egresos;
        this.presupuestos = response.data.presupuestos;
        this.proveedores = response.data.proveedores;
        this.colillas = response.data.colillas;
        this.listLength = this.datos.length;
    });
  },
   methods: {
    buscar(){
        var total1 = 0;
        var total2 = 0;
        var disponible = 0;
        var presupuesto = document.getElementById('presupuesto').value;
        for(var key in this.presupuestos){
            if(this.presupuestos[key].id == presupuesto){
                disponible = this.presupuestos[key].monto;
            }
        }
        var aux = new Array();
        for(var key in this.egresos){
            if(this.egresos[key].presupuesto == presupuesto){
                    var aux2 ={'numero': '' , 'fecha':'','monto':'','estado':''};
                    aux2.numero= this.egresos[key].numero_documento;
                    var fecha = new Date(this.egresos[key].created_at);
                    aux2.fecha= fecha.getUTCFullYear() +'-'+fecha.getUTCMonth()+'-'+fecha.getUTCDate();
                    aux2.monto = this.egresos[key].monto;
                    aux2.estado = this.egresos[key].estado;
                    aux.push(aux2);
                    total1 = parseFloat(total1 + this.egresos[key].monto);
            }
        }
        for(var key in this.colillas){
            if(this.colillas[key].presupuesto == presupuesto){
                    var aux1 ={'numero': '' ,'fecha':'','monto':'','estado':'' ,};
                    aux1.numero= this.colillas[key].numero_Factura;
                    var fecha = new Date(this.colillas[key].created_at);
                    aux1.fecha= fecha.getUTCFullYear() +'-'+fecha.getUTCMonth()+'-'+fecha.getUTCDate();
                    aux1.monto = this.colillas[key].neto;
                    aux1.estado = this.colillas[key].estado;
                    aux.push(aux1);
                    total2 = parseFloat(total2 + this.colillas[key].neto);

            }
        }
        this.datos = aux;
         var total = parseFloat(total1 +total2);
        document.getElementById('total').value = total;
        document.getElementById('disponible').value = disponible;


    },
  },
};
</script>