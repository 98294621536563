<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-6 col-xs-6">
                            <h4 class="title">Recibos</h4>
                        </div>
                       <div class="col-sm-6 col-xs-6 text-right">
                            <div class="btn_group">
                                <router-link to="/addRecibos"  id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear recibo <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table  id="recibos" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Documento</th>
                            <th>Nombre asociado</th>
                            <th>Modo de pago</th>
                            <th>Frecuencia</th>
                            <th>Fecha de registro</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="recibo in recibos" :key="recibo.id">
                          <td>{{ recibo.id }}</td>
                          <td>{{ recibo.nombreAsociado }} {{ recibo.apellido }}</td>
                          <td>{{ recibo.modo }}</td>
                          <td>{{ recibo.metodo }}</td>
                          <td> {{ format_date(recibo.created_at) }}</td>
                          <td>
                          <div class="btn-group" role="group">
                             <router-link :to="{ name: 'editRecibos', params: { id: recibo.id} }" class="btn btn-success">
                                <i class="fas fa-edit"></i>
                              </router-link>

                              <button class="btn btn-primary" @click="descargar(recibo.id)" >
                                <i class="fas fa-file-download"></i>
                              </button>
                              <button class="btn btn-danger" @click="deleteRecibo(recibo.id)" >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                          </div>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import NoExist from "../Shared/NoExist.vue";
export default {
   inject:['reload'],
     components: {
		  NoExist
	},
  data() {
    return {
      recibos: [],
      pageSize:5,
      datos:[],
      paginaActual:1,
      listLength:0
    };
  },
  created() {
    this.axios.get(`/api/recibos`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {

        this.recibos = response.data;
        this.listLength = this.recibos.length;
        this.getDataPagina(1);
    });
  },
   methods: {
    deleteRecibo(id) {
              Swal.fire({
          title:'¿Desea eliminar el recibo?',
          confirmButtonColor:"red",
          showCancelButton:true,
          confirmButtonText:"Delete",
          icon:"error"
    }).then(res =>{
      if (res.value) {
          this.axios
          .delete(`/api/recibos/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
          .then((response) => {
            let i = this.recibos.map((data) => data.id).indexOf(id);
            this.recibos.splice(i, 1);
            this.reload();
        });
      }
    })
    },
   async descargar(id) {
      try {
        const response = await this.axios.get(`/api/recibo-asociado/${id}/pdf`, {
          responseType: 'blob',  // Para manejar la respuesta como un archivo Blob
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        });
        const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.target = '_blank';
        fileLink.click();
      } catch (error) {
        console.error('Error al generar el recibo:', error);
      }
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('MM/DD/YYYY hh:mm:ss')
          }
      },
    totalPaginas(){
       return Math.ceil(this.recibos.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.recibos.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     }
  },
};
</script>