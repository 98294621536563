<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">Datos de llamadas</h3>
                    <form @submit.prevent="addLlamadas">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input type="text" class="form-control" v-model="llamada.nombre" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Número de teléfono</label>
                                    <input type="text" class="form-control" v-model="llamada.numero_telefono" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Tiempo</label>
                                    <input type="number" id="minutos" min="1" max="60" class="form-control"
                                        style="width: 10%" v-model="llamada.tiempo" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label>Autorizado por</label>
                                    <select class="form-control" v-model="llamada.autorizado_por">
                                        <option value="Administrador">
                                            Administrador
                                        </option>
                                        <option v-for="oficina in oficinas" :key="oficina.id" :value="oficina.nombre"
                                            v-text="oficina.nombre"></option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btn btn-success">
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            llamadas: [],
            joven: {},
            oficinas: [],
            llamada: { joven: "" },
        };
    },
    created() {
        this.axios
            .get(`/api/llamadas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.llamadas = response.data.llamadas;
                this.joven = response.data.joven;
                this.oficinas = response.data.oficinas;
            });
    },
    methods: {
        addLlamadas() {
            this.llamada.joven = this.joven.id;
            this.axios
                .post("/api/llamadas", this.llamada, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => location.reload())
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Datos de llamada guardados!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
};
</script>
