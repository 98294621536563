<template>
    <div class="row">
        <div class="col">
            <span>No hay resultados</span>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    methods: {
    },
  };
</script>