<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Oficinas</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link
                                    to="/addOficinas"
                                    id="agregar"
                                    class="btn btn-info pull-left"
                                    style="margin-top: -7px; margin-left: 2px"
                                    >Crear Oficina
                                    <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="oficina in searchOficina"
                                :key="oficina.id"
                            >
                                <td class="text-center">
                                    {{ oficina.nombre }}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <router-link
                                            :to="{
                                                name: 'editOficina',
                                                params: { id: oficina.id },
                                            }"
                                            class="btn btn-success"
                                            ><i class="fas fa-edit"></i
                                        ></router-link>
                                        <button
                                            class="btn btn-danger"
                                            @click="deleteOficina(oficina.id)"
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav
                        aria-label="Page navigation example"
                        v-if="listLength != 0"
                    >
                        <ul class="pagination justify-content-center">
                            <li
                                class="page-item"
                                v-on:click="getPreviousPage()"
                            >
                                <a class="page-link" href="#"></a>
                            </li>
                            <li
                                v-for="pagina in totalPaginas()"
                                v-on:click="getDataPagina(pagina)"
                                class="page-item"
                                v-bind:key="isActive(pagina)"
                            >
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
function buscarEnObjecto(objecto, palabra) {
    for (let key in objecto) {
        if (
            objecto.hasOwnProperty(key) &&
            objecto[key].toString().toLowerCase().includes(palabra)
        ) {
            return true;
        }
    }

    return false;
}
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            oficinas: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
            busqueda: "",
            listLength: 0,
        };
    },
    computed: {
        searchOficina: function () {
            return this.datos.filter((oficina) => {
                return buscarEnObjecto(oficina, this.busqueda.toLowerCase());
            });
        },
    },
    created() {
        this.axios.get("/api/oficinas", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.oficinas = response.data;
            this.listLength = this.oficinas.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deleteOficina(id) {
            this.axios.delete(`/api/oficinas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                let i = this.oficinas.map((data) => data.id).indexOf(id);
                this.oficinas.splice(i, 1);
                this.reload();
            });
            Swal.fire({
                title: "Eliminada!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.oficinas.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.oficinas.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
