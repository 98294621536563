<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.go(-1)"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">
                        Registro de horas extras
                    </h3>
                    <form @submit.prevent="AddHorasExtras">
                        <div class="row">
                            <div class="form-group col">
                                <label>Fecha</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    v-model="horaextra.fecha"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Hora inicio</label>
                                <input
                                    type="time"
                                    class="form-control"
                                    v-model="horaextra.hora_inicio"
                                />
                            </div>
                            <div class="form-group col">
                                <label>Hora fin</label>
                                <input
                                    type="time"
                                    class="form-control"
                                    v-model="horaextra.hora_fin"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Cantidad de horas</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="horaextra.cantidad_horas"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Comentarios</label>
                                <textarea
                                    type="text"
                                    class="form-control"
                                    v-model="horaextra.comentarios"
                                    rows="3"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btn btn-success">
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            horasextra: [],
            horaextra: {},
        };
    },
    created() {
        this.axios
            .get(`/api/horasextrasempleados/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.horasextra = response.data.horasextras;
            });
    },
    methods: {
        AddHorasExtras() {
            this.axios
                .post("/api/horasextrasempleados", this.horaextra, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => location.reload())
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Se han registrado las horas extras!",
                showConfirmButton: false,
                timer: 2000,
            });
        },
    },
};
</script>
