<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></a>
          <h3 class="title text-center mt-4">Vacunas</h3>
          <form @submit.prevent="updateVacuna">
            <div class="form-group">
              <label>Fecha</label>
              <input type="date" class="form-control" v-model="vacuna.fecha" />
            </div>
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" class="form-control" v-model="vacuna.nombre" />
            </div>
            <div class="form-group">
              <label>Detalle</label>
              <input type="text" class="form-control" v-model="vacuna.detalle" />
            </div>
            <div class="form-group">
              <label>Estado</label>
              <select class="form-control" v-model="vacuna.estado">
                <option value="Aplicada">Aplicada</option>
                <option value="Pendiente">Pendiente</option>
              </select>
            </div>
            <button type="submit" class="btn btn-success">Editar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vacuna: {},
    };
  },
  created() {
    this.axios
      .get(`/api/vacunas/${this.$route.params.id}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then((response) => {
        this.vacuna = response.data.vacuna;
      });
  },
  methods: {
    updateVacuna() {
      this.axios
        .patch(
          `/api/vacunas/${this.$route.params.id}`,
          this.vacuna, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        }
        )
        .then((res) => {
          this.$router.go(0);
        });
      Swal.fire({
        icon: 'success',
        title: 'Vacuna Actualizada!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>