<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col">
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col text-center"
                            style="margin-left: 5%"
                            v-if="listLength != 0"
                        >
                            <label id="reportes">Joven</label>
                            <select
                                id="joven"
                                style="
                                    width: 200px;
                                    height: 29px;
                                    border-radius: 5px;
                                "
                            >
                                <option
                                    id="datos"
                                    v-for="joven in jovenes"
                                    :key="joven.id"
                                    :value="joven.id"
                                    v-text="joven.nombre"
                                ></option>
                            </select>
                        </div>
                        <div class="col">
                            <label style="margin-left: 10px"
                                >Fecha Inicio</label
                            >
                            <input
                                type="date"
                                id="fecha_inicio"
                                style="border-radius: 5px; width: 150px"
                            />
                        </div>
                        <div class="col">
                            <label style="margin-left: 15px">Fecha Final</label>
                            <input
                                type="date"
                                id="fecha_final"
                                style="border-radius: 5px; width: 150px"
                            />
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                class="btn btn-info"
                                style="width: 150px"
                                @click="buscar()"
                            >
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                style="
                                    background-color: #fec107;
                                    border-color: #fec107;
                                    width: 150px;
                                "
                                class="btn btn-danger"
                                @click="generateReport()"
                            >
                                Descargar PDF
                                <i class="fa-solid fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="panel-body table-responsive table-responsive-md table-responsive-lg">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Numero de telefono</th>
                                <th class="text-center">Tiempo</th>
                                <th class="text-center">Autorizado por</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td class="text-center">
                                    {{ reporteL.nombre }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.numero_telefono }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.tiempo }} minutos
                                </td>
                                <td class="text-center">
                                    {{ reporteL.autorizado_por }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="Reporte Llamadas"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        pdf-orientation="landscape"
                        pdf-content-width="800px"
                        ref="html2Pdf"
                    >
                        <section
                            slot="pdf-content"
                            style="
                                color: black;
                                padding-left: 21%;
                                padding-top: 2%;
                            "
                            class="
                                align-items-center
                                justify-content-center
                                text-center
                            "
                        >
                            <h2 class="text-center" style="margin-left: 23%">
                                Reporte de llamadas
                            </h2>
                            <div
                                class="panel-body table-responsive"
                                style="width: 800px"
                            >
                                <NoExist v-if="listLength == 0" />
                                <table
                                    class="
                                        table
                                        table-bordered
                                        table-striped
                                        table-condensed
                                    "
                                    v-if="listLength != 0"
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">Nombre</th>
                                            <th class="text-center">
                                                Numero de telefono
                                            </th>
                                            <th class="text-center">Tiempo</th>
                                            <th class="text-center">
                                                Autorizado por
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="reporteL in datos"
                                            :key="reporteL.id"
                                        >
                                            <td class="text-center">
                                                {{ reporteL.nombre }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.numero_telefono }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.tiempo }} minutos
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.autorizado_por }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            jovenes: [],
            name: "",
            fechaInicio: "",
            fechaFin: "",
            llamadas: [],
            periodo: [],
            datos: {},
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/llamadas`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.jovenes = response.data.jovenes;
            this.llamadas = response.data.llamadas;
            this.periodos = response.data.periodos;
            this.listLength = this.datos.length;
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            this.name = document.getElementById("joven").value2;
            this.fechaInicio = document.getElementById("fecha_inicio").value;
            this.fechaFin = document.getElementById("fecha_final").value;
            var joven = document.getElementById("joven").value;
            var fecha_inicio = document.getElementById("fecha_inicio").value;
            var fecha_final = document.getElementById("fecha_final").value;
            var aux = new Array();
            for (var key in this.llamadas) {
                if (this.llamadas[key].joven == joven) {
                    if (
                        this.llamadas[key].created_at >= fecha_inicio &&
                        this.llamadas[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            numero_telefono: "",
                            tiempo: "",
                            autorizado_por: "",
                        };
                        aux2.nombre = this.llamadas[key].nombre;
                        aux2.numero_telefono =
                            this.llamadas[key].numero_telefono;
                        aux2.tiempo = this.llamadas[key].tiempo;
                        aux2.autorizado_por = this.llamadas[key].autorizado_por;
                        var fecha = new Date(this.llamadas[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.periodos) {
                if (this.periodos[key].joven == joven) {
                    if (
                        this.periodos[key].created_at >= fecha_inicio &&
                        this.periodos[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = "";
                        aux2.categoria = "Periodo";
                        var fecha = new Date(this.periodos[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = "Registrado";
                        aux.push(aux2);
                    }
                }
            }

            this.datos = aux;
        },
    },
};
</script>
