import Vue from 'vue'
import VueRouter from 'vue-router'


import HomeView from '@/views/HomeView.vue'
import Login from '@/views/LoginView.vue';
import store from '@/store';
//Jovenes
//// Jovenes
import Jovenes from '@/components/JovenesCRUD/Joven.vue';
import CreateUpdateJoven from '@/components/JovenesCRUD/CreateUpdateJoven.vue';
import MenuJovenes from '@/components/JovenesCRUD/MenuJovenes.vue';


//Usuarios
import Usuarios from '@/components/UsuariosCRUD/Usuario.vue';
import AddUsuario from '@/components/UsuariosCRUD/CreateUsuario.vue';
import UpdateUsuario from '@/components/UsuariosCRUD/UpdateUsuario.vue';
//Eventos
import Eventos from '@/components/EventosCRUD/Eventos.vue';
import CreateUpdateEventos from '@/components/EventosCRUD/CreateUpdateEventos.vue';
//Tipos de Eventos
import Tipos from '@/components/EventosCRUD/Tipos/Tipos.vue';
import AddTipos from '@/components/EventosCRUD/Tipos/CreateTipos.vue';
import UpdateTipos from '@/components/EventosCRUD/Tipos/UpdateTipos.vue';

//Expediente Educativo
import Expedientes from '@/components/JovenesCRUD/ExpedienteEducativo/Expedientes.vue';
import AddExpedientes from '@/components/JovenesCRUD/ExpedienteEducativo/CreateExpedientes.vue';
import UpdateExpedientes from '@/components/JovenesCRUD/ExpedienteEducativo/UpdateExpedientes.vue';
import VerExpediente from '@/components/JovenesCRUD/ExpedienteEducativo/VerExpediente.vue';

//Titulos
import Titulos from '@/components/JovenesCRUD/Titulos/Titulos.vue';
import AddTitulos from '@/components/JovenesCRUD/Titulos/CreateTitulos.vue';

//Citas
import Citas from '@/components/JovenesCRUD/Citas/Citas.vue';
import AddCitas from '@/components/JovenesCRUD/Citas/CreateCitas.vue';
import UpdateCitas from '@/components/JovenesCRUD/Citas/UpdateCitas.vue';

//Recetas
import Recetas from '@/components/JovenesCRUD/Recetas/Recetas.vue';
import AddRecetas from '@/components/JovenesCRUD/Recetas/CreateRecetas.vue';
import UpdateRecetas from '@/components/JovenesCRUD/Recetas/UpdateRecetas.vue';

//Evolucion Medica
import Evoluciones from '@/components/JovenesCRUD/EvolucionMedica/Evoluciones.vue';
import AddEvoluciones from '@/components/JovenesCRUD/EvolucionMedica/CreateEvolucion.vue';
import UpdateEvoluciones from '@/components/JovenesCRUD/EvolucionMedica/UpdateEvolucion.vue';

//Estado Nutricional
import Nutriciones from '@/components/JovenesCRUD/EstadoNutricional/Nutriciones.vue';
import AddNutriciones from '@/components/JovenesCRUD/EstadoNutricional/CreateNutricion.vue';
import UpdateNutriciones from '@/components/JovenesCRUD/EstadoNutricional/UpdateNutricion.vue';

// Vacunas
import Vacunas from '@/components/JovenesCRUD/Vacunas/Vacunas.vue';
import AddVacunas from '@/components/JovenesCRUD/Vacunas/CreateVacunas.vue';
import UpdateVacunas from '@/components/JovenesCRUD/Vacunas/UpdateVacunas.vue';

//Personal Acompañante
import Personales from '@/components/JovenesCRUD/PersonalAcompanante/Personales.vue';
import AddPersonales from '@/components/JovenesCRUD/PersonalAcompanante/CreatePersonales.vue';
import UpdatePersonales from '@/components/JovenesCRUD/PersonalAcompanante/UpdatePersonales.vue';

//Laboratorios
import Laboratorios from '@/components/JovenesCRUD/Laboratorios/Laboratorios.vue';
import AddLaboratorios from '@/components/JovenesCRUD/Laboratorios/CreateLaboratorios.vue';

//Epicrisis
import Epicrisis from '@/components/JovenesCRUD/Epicrisis/Epicrisis.vue';
import AddEpicrisis from '@/components/JovenesCRUD/Epicrisis/CreateEpicrisis.vue';

//Periodo
import Periodos from '@/components/JovenesCRUD/PeriodoMestrual/Periodo.vue';
import AddPeriodos from '@/components/JovenesCRUD/PeriodoMestrual/CreatePeriodo.vue';

///Padecimientos
import Padecimientos from '@/components/JovenesCRUD/Padecimientos/Padecimientos.vue';
import AddPadecimientos from '@/components/JovenesCRUD/Padecimientos/CreatePadecimientos.vue';
import UpdatePadecimientos from '@/components/JovenesCRUD/Padecimientos/UpdatePadecimientos.vue';

///Dolencias
import Dolencias from '@/components/JovenesCRUD/Dolencias/Dolencias.vue';
import AddDolencias from '@/components/JovenesCRUD/Dolencias/CreateDolencias.vue';
import UpdateDolencias from '@/components/JovenesCRUD/Dolencias/UpdateDolencias.vue';

///Cirugias
import Cirugias from '@/components/JovenesCRUD/Cirugias/Cirugias.vue';
import AddCirugias from '@/components/JovenesCRUD/Cirugias/CreateCirugias.vue';
import UpdateCirugias from '@/components/JovenesCRUD/Cirugias/UpdateCirugias.vue';

//Pertenencias
import Pertenencias from '@/components/JovenesCRUD/Pertenencias/Pertenencias.vue';
import AddPertenencias from '@/components/JovenesCRUD/Pertenencias/CreatePertenencia.vue';
import UpdatePertenencias from '@/components/JovenesCRUD/Pertenencias/UpdatePertenencia.vue';

//Llamadas
import Llamadas from '@/components/JovenesCRUD/Llamadas/Llamadas.vue';
import AddLlamadas from '@/components/JovenesCRUD/Llamadas/CreateLlamadas.vue';
import UpdateLlamadas from '@/components/JovenesCRUD/Llamadas/UpdateLlamadas.vue';

// Proveedores
import Proveedores from '@/components/ProveedoresCRUD/Proveedor.vue';
import CreateUpdateProveedor from '@/components/ProveedoresCRUD/CreateUpdateProveedor.vue';
// Colaboradores
import Colaboradores from '@/components/ColaboradoresCRUD/Colaboradores.vue';
import CreateUpdateColaborador from '@/components/ColaboradoresCRUD/CreateUpdateColaborador.vue';
//Colillas
import Colillas from '@/components/ColaboradoresCRUD/Colillas/Colillas.vue';
import AddColillas from '@/components/ColaboradoresCRUD/Colillas/CreateColillas.vue';
import UpdateColillas from '@/components/ColaboradoresCRUD/Colillas/UpdateColillas.vue';

// Eventos Colaboradores
import EventosColaboradores from '@/components/ColaboradoresCRUD/EventosColaboradores.vue';
import AddEventosColaboradores from '@/components/ColaboradoresCRUD/CreateEventosColaboradores.vue';

//////Presupuestos
import Presupuesto from '@/components/PresupuestosCRUD/Presupuesto.vue';
import AddPresupuesto from '@/components/PresupuestosCRUD/CreatePresupuesto.vue';
import UpdatePresupuesto from '@/components/PresupuestosCRUD/UpdatePresupuesto.vue';
import RubrosPresupuesto from '@/components/PresupuestosCRUD/RubrosPresupuesto.vue';
import RubrosPresupuestoEditar from '@/components/PresupuestosCRUD/UpdateRubros.vue';

/////Egresos
import Egresos from '@/components/EgresosCRUD/Egresos.vue';
import AddEgreso from '@/components/EgresosCRUD/CreateEgreso.vue';
import UpdateEgreso from '@/components/EgresosCRUD/UpdateEgreso.vue';

/// Asociados y Recibos
import Asociado from '@/components/AsociadosCRUD/Asociado.vue';
import AddAsociado from '@/components/AsociadosCRUD/CreateAsociado.vue';
import Recibo from '@/components/RecibosCRUD/Recibo.vue';
import CreateRecibos from '@/components/RecibosCRUD/CreateRecibos.vue';
import RecibosAsociado from '@/components/RecibosCRUD/RecibosAsociado.vue';

//Oficinas
import Oficinas from '@/components/OficinasCRUD/Oficinas.vue';
import CreateUpdateOficina from '@/components/OficinasCRUD/CreateUpdateOficina.vue';

//Empresas
import Empresas from '@/components/EmpresasCRUD/Empresas.vue';
import CreateUpdateEmpresa from '@/components/EmpresasCRUD/CreateUpdateEmpresa.vue';

////Vacaciones Empleados
import VacacionesAdm from '@/components/VacacionesAdm/VacacionesAdm.vue';
import UpdateVacacionesAdm from '@/components/VacacionesAdm/UpdateVacacionesAdm.vue';

////Permisos administrador
import PermisosAdm from '@/components/PermisosAdm/PermisosAdm.vue';
import UpdatePermisosAdm from '@/components/PermisosAdm/UpdatePermisosAdm.vue';

////Vacaciones Empleados
import VacacionesEmpleados from '@/components/VacacionesEmpleados/VacacionesEmpleados.vue';
import AddVacacionesEmpleados from '@/components/VacacionesEmpleados/CreateVacaciones.vue';

//Permisos empleados
import PermisosEmpleados from '@/components/PermisosEmpleados/PermisosEmpleado.vue';
import AddPermisosEmpleados from '@/components/PermisosEmpleados/CreatePermisoEmpleado.vue';

////Horas Extras Administrador
import HorasExtras from '@/components/HorasExtrasCRUD/HorasExtras.vue';


////Horas Extras Empleados
import HorasExtrasEmpleados from '@/components/HorasExtrasEmpleados/HorasExtrasEmpleados.vue';
import AddHorasExtras from '@/components/HorasExtrasEmpleados/CreateHorasExtrasEmpleados.vue';

////Feriados Administrador
import Feriados from '@/components/FeriadosCRUD/Feriados.vue';

////Incapacidades
import Incapacidades from '@/components/IncapacidadesCRUD/Incapacidades.vue';
import CreateUpdateIncapacidades from '@/components/IncapacidadesCRUD/CreateUpdateIncapacidades.vue';


////Feriados Empleados
import FeriadosEmpleados from '@/components/FeriadosEmpleados/FeriadosEmpleados.vue';
import AddFeriados from '@/components/FeriadosEmpleados/CreateFeriados.vue';

//Recibos Colaborador 
import RecibosColaborador from '@/components/RecibosColaboradores/RecibosColaboradores.vue';
import AddRecibosColaboradores from '@/components/RecibosColaboradores/CreateRecibosColaboradores.vue';

////Aguinaldos
import Aguinaldos from '@/components/AguinaldosCRUD/Aguinaldos.vue';
import ShowAguinaldos from '@/components/AguinaldosCRUD/ShowAguinaldos.vue';

//Reportes
import MenuReportes from '@/components/Reportes/MenuReportes.vue';
import ReportePlanilla from '@/components/Reportes/ReportePlanilla.vue';
import ReporteFechasJ from '@/components/Reportes/ReporteFechasJ.vue';
import ReporteExpediente from '@/components/Reportes/ReporteExpediente.vue';
import ReporteLlamadas from '@/components/Reportes/ReporteLlamadas.vue';
import ReporteLiquidacion from '@/components/Reportes/ReporteLiquidacion.vue';
import ReporteEstadoP from '@/components/Reportes/ReporteEstadoP.vue';
import ReporteEventos from '@/components/Reportes/ReporteEventos.vue';
import ReporteVacacionesColaborador from '@/components/Reportes/ReporteVacacionesColaborador.vue';
import ReporteVacacionesPendientes from '@/components/Reportes/ReporteVacacionesPendientes.vue';

import NotFound from '@/views/NotFound.vue';

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    component: HomeView,
    meta: { requiresAuth: true },
    children: [

      {
        path: '',
        name: 'home',
        component: Eventos
      },
      //######## USUARIOS
      {
        name: 'usuarios',
        path: '/usuarios',
        component: Usuarios
      },
      {
        name: 'addUsuario',
        path: '/addUsuario',
        component: AddUsuario
      },
      {
        name: 'editUsuario',
        path: '/editUsuario/:id',
        component: UpdateUsuario
      },
      //########### EVENTOS
      {
        path: '/eventos/:jovenId',
        name: 'eventosByJoven',
        component: Eventos,
      },
      {
        path: '/eventos',
        name: 'eventos',
        component: Eventos,
      },
      {
        path: '/addEventos',
        name: 'addEventos',
        component: CreateUpdateEventos,
      },
      {
        path: '/editEventos/:id',
        name: 'editEventos',
        component: CreateUpdateEventos,
      },
      //########## TIPOS
      {
        path: '/addTipos',
        name: 'addTipos',
        component: AddTipos,
      },
      {
        path: '/editTipos/:id',
        name: 'editTipos',
        component: UpdateTipos,
      },
      {
        path: '/tipos',
        name: 'tipos',
        component: Tipos,
      },
      //########### JOVENES 
      {
        path: '/addjoven',
        name: 'addjoven',
        component: CreateUpdateJoven,
      },
      {
        path: '/editjoven/:id',
        name: 'editjoven',
        component: CreateUpdateJoven,
      },
      {
        path: '/jovenes',
        name: 'jovenes',
        component: Jovenes,
      },
      {
        name: 'menuJovenes',
        path: '/menuJovenes/:id',
        component: MenuJovenes
      },
      //######### EXPEDIENTES
      {
        name: 'expedientes',
        path: '/jovenes/expedientes/:id',
        component: Expedientes
      },
      {
        name: 'addExpedientes',
        path: '/jovenes/addExpedientes/:id',
        component: AddExpedientes
      },
      {
        name: 'editExpedientes',
        path: '/jovenes/editExpedientes/:id',
        component: UpdateExpedientes
      },
      {
        name: 'verExpediente',
        path: '/jovenes/verExpediente/:id',
        component: VerExpediente
      },
      // ############ TITULOS
      {
        name: 'titulos',
        path: 'jovenes/titulos/:id',
        component: Titulos
      },
      {
        name: 'addTitulos',
        path: 'jovenes/addTitulos/:id',
        component: AddTitulos
      },
      // ############## CITAS
      {
        name: 'citas',
        path: 'jovenes/citas/:id',
        component: Citas
      },
      {
        name: 'addCitas',
        path: 'jovenes/addCitas/:id',
        component: AddCitas
      },
      {
        name: 'editCitas',
        path: 'jovenes/editCitas/:id',
        component: UpdateCitas
      },
      // ########### RECETAS
      {
        name: 'recetas',
        path: 'jovenes/recetas/:id',
        component: Recetas
      },
      {
        name: 'addRecetas',
        path: 'jovenes/addRecetas/:id',
        component: AddRecetas
      },
      {
        name: 'editRecetas',
        path: 'jovenes/editRecetas/:id',
        component: UpdateRecetas
      },
      // ######## EVOLUCIÓN MEDICA
      {
        name: 'evoluciones',
        path: 'jovenes/evoluciones/:id',
        component: Evoluciones
      },
      {
        name: 'addEvoluciones',
        path: 'jovenes/addEvoluciones/:id',
        component: AddEvoluciones
      },
      {
        name: 'editEvoluciones',
        path: 'jovenes/editEvoluciones/:id',
        component: UpdateEvoluciones
      },
      // ######## NUTRICIONES
      {
        name: 'nutriciones',
        path: 'jovenes/nutriciones/:id',
        component: Nutriciones
      },
      {
        name: 'addNutriciones',
        path: 'jovenes/addNutriciones/:id',
        component: AddNutriciones
      },
      {
        name: 'editNutriciones',
        path: 'jovenes/editNutriciones/:id',
        component: UpdateNutriciones
      },
      // ######## VACUNAS
      {
        name: 'vacunas',
        path: 'jovenes/vacunas/:id',
        component: Vacunas
      },
      {
        name: 'addVacunas',
        path: 'jovenes/addVacunas/:id',
        component: AddVacunas
      },
      {
        name: 'editVacunas',
        path: 'jovenes/editVacunas/:id',
        component: UpdateVacunas
      },
      // ######## PERSONAL ACOMPAÑANTE
      {
        name: 'personales',
        path: '/personales/:id',
        component: Personales
      },
      {
        name: 'addPersonales',
        path: '/addPersonales/:id',
        component: AddPersonales
      },
      {
        name: 'editPersonales',
        path: '/editPersonales/:id',
        component: UpdatePersonales
      },
      // ######### LABORATORIOS
      {
        name: 'laboratorios',
        path: 'jovenes/laboratorios/:id',
        component: Laboratorios
      },
      {
        name: 'addLaboratorios',
        path: 'jovenes/addLaboratorios/:id',
        component: AddLaboratorios
      },
      // ######### EPICRISIS
      {
        name: 'epicrisis',
        path: 'jovenes/epicrisis/:id',
        component: Epicrisis
      },
      {
        name: 'addEpicrisis',
        path: 'jovenes/addEpicrisis/:id',
        component: AddEpicrisis
      },
      // ######## PERIODOS
      {
        name: 'periodos',
        path: 'jovenes/periodos/:id',
        component: Periodos
      },
      {
        name: 'addPeriodo',
        path: 'jovenes/addPeriodo/:id',
        component: AddPeriodos
      },
      // ###### PADECIMIENTOS 
      {
        name: 'padecimientos',
        path: 'jovenes/padecimientos/:id',
        component: Padecimientos
      },
      {
        name: 'addPadecimientos',
        path: 'jovenes/addPadecimientos/:id',
        component: AddPadecimientos
      },
      {
        name: 'editPadecimientos',
        path: 'jovenes/editPadecimientos/:id',
        component: UpdatePadecimientos
      },
      // ########## DOLENCIAS
      {
        name: 'dolencias',
        path: 'jovenes/dolencias/:id',
        component: Dolencias
      },
      {
        name: 'addDolencias',
        path: 'jovenes/addDolencias/:id',
        component: AddDolencias
      },
      {
        name: 'editDolencias',
        path: 'jovenes/editDolencias/:id',
        component: UpdateDolencias
      },
      //######### CIRUGIAS
      {
        name: 'cirugias',
        path: 'jovenes/cirugias/:id',
        component: Cirugias
      },
      {
        name: 'addCirugias',
        path: 'jovenes/addCirugias/:id',
        component: AddCirugias
      },
      {
        name: 'editCirugias',
        path: 'jovenes/editCirugias/:id',
        component: UpdateCirugias
      },
      //######### PERTENENCIAS
      {
        name: 'pertenencias',
        path: '/jovenes/pertenencias/:id',
        component: Pertenencias
      },
      {
        name: 'addPertenencias',
        path: '/jovenes/addPertenencias/:id',
        component: AddPertenencias
      },
      {
        name: 'editPertenencias',
        path: '/jovenes/editPertenencias/:id',
        component: UpdatePertenencias
      },
      //######### LLAMADAS
      {
        name: 'llamadas',
        path: '/llamadas/:id',
        component: Llamadas
      },
      {
        name: 'addLlamadas',
        path: '/addLlamadas/:id',
        component: AddLlamadas
      },
      {
        name: 'editLlamadas',
        path: '/editLlamadas/:id',
        component: UpdateLlamadas
      },
      //######### PROVEEDORES
      {
        path: '/proveedores',
        name: 'proveedores',
        component: Proveedores,
      },
      {
        name: 'addProveedor',
        path: '/addProveedor',
        component: CreateUpdateProveedor
      },
      {
        name: 'editProveedor',
        path: '/editProveedor/:id',
        component: CreateUpdateProveedor
      },
      //######### COLABORADORES
      {
        path: '/colaboradores',
        name: '/colaboradores',
        component: Colaboradores,
      },
      {
        name: 'addColaboradores',
        path: '/addColaboradores',
        component: CreateUpdateColaborador
      },
      {
        name: 'editColaboradores',
        path: '/editColaboradores',
        component: CreateUpdateColaborador
      },
      // ########### COLILLAS
      {
        path: '/addColillas',
        name: 'addColillas',
        component: AddColillas,
      },
      {
        path: '/editColillas/:id',
        name: 'editColillas',
        component: UpdateColillas,
      },
      {
        path: '/colillas',
        name: 'colillas',
        component: Colillas,
      },
      // ##########  EVENTOS COLABORADORES
      {
        name: 'eventosColaboradores',
        path: '/eventosColaboradores/:id',
        component: EventosColaboradores,
      },
      {
        name: 'addEventosColaboradores',
        path: '/addEventosColaboradores/:id',
        component: AddEventosColaboradores
      },
      // ############ PRESUPUESTOS Y RUBROS
      {
        path: '/presupuestos',
        name: 'presupuestos',
        component: Presupuesto,
      }, ,
      {
        name: 'addPresupuesto',
        path: '/addPresupuesto',
        component: AddPresupuesto
      },
      {
        name: 'editPresupuesto',
        path: '/editPresupuesto/:id',
        component: UpdatePresupuesto
      },
      {
        name: 'addRubro',
        path: '/addRubro/:id',
        component: RubrosPresupuesto
      },
      {
        name: 'editRubro',
        path: '/editRubro/:id',
        component: RubrosPresupuestoEditar
      },
      // ########## EGRESOS
      {
        path: '/addEgreso',
        name: 'addEgreso',
        component: AddEgreso,
      },
      {
        path: '/egresos',
        name: 'egresos',
        component: Egresos,
      },
      {
        name: 'editEgreso',
        path: '/editEgreso/:id',
        component: UpdateEgreso
      },
      // ########### Asociados y Recibos
      {
        name: 'asociados',
        path: '/asociados',
        component: Asociado
      },
      {
        name: 'addAsociado',
        path: '/addAsociado',
        component: AddAsociado
      },
      {
        name: 'editAsociado',
        path: '/editAsociado/:id',
        component: AddAsociado
      },
      {
        name: 'recibosAsociado',
        path: '/recibosAsociado/:id',
        component: RecibosAsociado
      },
      {
        name: 'recibos',
        path: '/recibos',
        component: Recibo
      },
      {
        name: 'addRecibos',
        path: '/addRecibos',
        component: CreateRecibos
      },
      {
        name: 'editRecibos',
        path: '/editRecibos/:id',
        component: CreateRecibos
      },
      // ####### OFICINAS
      {
        path: '/addOficinas',
        name: 'addOficinas',
        component: CreateUpdateOficina,
      },
      {
        path: '/oficinas',
        name: 'oficinas',
        component: Oficinas,
      },
      {
        name: 'editOficina',
        path: '/editOficina/:id',
        component: CreateUpdateOficina
      },
      
      // ####### EMPRESAS
      {
        path: '/addEmpresas',
        name: 'addEmpresas',
        component: CreateUpdateEmpresa,
        meta: { requiresAuth: true, role: 'SuperAdministrador' }
      },
      {
        path: '/empresas',
        name: 'empresas',
        component: Empresas,
        meta: { requiresAuth: true, role: 'SuperAdministrador' }
      },
      {
        name: 'editEmpresa',
        path: '/editEmpresa/:id',
        component: CreateUpdateEmpresa,
        meta: { requiresAuth: true, role: 'SuperAdministrador' }
      },
      //########### VACACIONES ADM
      {
        path: '/vacacionesadm',
        name: 'vacacionesadm',
        component: VacacionesAdm,
      },
      {
        name: 'editVacacionesAdm',
        path: '/editVacacionesAdm/:id',
        component: UpdateVacacionesAdm
      },
      // ######### PERMISOS ADM
      {
        path: '/permisosadm',
        name: 'permisosadm',
        component: PermisosAdm,
      },
      {
        name: 'editPermisosAdm',
        path: '/editPermisosAdm/:id',
        component: UpdatePermisosAdm
      },
      // ######## VACACIONES EMPLEADOS
      {
        path: '/vacacionesempleados',
        name: 'vacacionesempleados',
        component: VacacionesEmpleados,
      },
      {
        name: 'addVacacionesEmpleados',
        path: '/addVacacionesEmpleados',
        component: AddVacacionesEmpleados
      },
      // ######### PERMISOS EMPLEADOS
      {
        path: '/permisosempleados',
        name: 'permisosempleados',
        component: PermisosEmpleados,
      },
      {
        name: 'addPermisosEmpleados',
        path: '/addPermisosEmpleados',
        component: AddPermisosEmpleados
      },
      // ######### HORAS EXTRA 
      {
        path: '/horasextras',
        name: 'horasextras',
        component: HorasExtras,
      },
      // ######### HORAS EXTRA EMPLEADOS
      {
        path: '/horasextrasempleados',
        name: 'horasextrasempleados',
        component: HorasExtrasEmpleados,
      },
      {
        name: 'addHorasExtrasEmpleados',
        path: '/addHorasExtrasEmpleados',
        component: AddHorasExtras
      },
      // ######### FERIADOS EMPLEADOS
      {
        path: '/feriadosempleados',
        name: 'feriadosempleados',
        component: FeriadosEmpleados,
      },
      {
        name: 'addFeriados',
        path: '/addFeriados',
        component: AddFeriados
      },
      // ######### FERIADOS 
      {
        path: '/feriados',
        name: 'feriados',
        component: Feriados,
      },
      // ######## INCAPACIDADES
      {
        path: '/incapacidades',
        name: 'incapacidades',
        component: Incapacidades,
      },
      {
        name: 'editIncapacidades',
        path: '/editIncapacidades/:id',
        component: CreateUpdateIncapacidades
      },
      {
        name: 'addIncapacidades',
        path: '/addIncapacidades',
        component: CreateUpdateIncapacidades
      },
      // ####### RECIBOS COLABORADOR
      {
        path: '/recibocolaborador',
        name: 'recibocolaborador',
        component: RecibosColaborador,
      },
      {
        name: 'addRecibosColaboradores',
        path: '/addRecibosColaboradores',
        component: AddRecibosColaboradores
      },
      // ######### AGUINALDOS
      {
        path: '/aguinaldos',
        name: 'aguinaldos',
        component: Aguinaldos,
      },
      {
        name: 'showAguinaldos',
        path: '/showAguinaldos/:id',
        component: ShowAguinaldos
      },
      // ######## REPORTES
      {
        path: '/reporteplanilla',
        name: 'reporteplanilla',
        component: ReportePlanilla,
      },
      {
        path: '/reportefechas',
        name: 'reportefechas',
        component: ReporteFechasJ,
      },
      {
        path: '/reportexpediente',
        name: 'reportexpediente',
        component: ReporteExpediente,
      },

      {
        path: '/reportevacacionescolaborador',
        name: 'reportevacacionescolaborador',
        component: ReporteVacacionesColaborador,
      },
      {
        path: '/reportevacacionespendientes',
        name: 'reportevacacionespendientes',
        component: ReporteVacacionesPendientes,
      },
      {
        path: '/reportellamadas',
        name: 'reportellamadas',
        component: ReporteLlamadas,
      },
      {
        path: '/reporteliquidacion',
        name: 'reporteliquidacion',
        component: ReporteLiquidacion,
      },
      {
        path: '/reportestado',
        name: 'reportestado',
        component: ReporteEstadoP,
      },
      {
        path: '/reporteeventos',
        name: 'reporteeventos',
        component: ReporteEventos,
      },
      {
        path: '/menuReportes',
        name: 'menuReportes',
        component: MenuReportes,
      },
    ]
  },
  {
    path: '*',  // Esta captura cualquier ruta no definida
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

]

//Controlla la navegacion redundante
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const userRole = store.getters.getUserRole;
  console.log(userRole);
  console.log(to.meta.role);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Esta ruta requiere autenticación, verifica si el usuario está autenticado
    if (!isAuthenticated) {
      // Si no está autenticado, redirige a la página de login
      next({
        name: 'Login',
        query: { redirect: to.fullPath } // Guardar la ruta a la que el usuario intentaba acceder
      });
    } else if (to.meta.role && to.meta.role !== userRole) {
      console.log("no se que pasa xd")
      next({ name: 'NotFound' });// Redirigir a una página de no autorizado si el rol no coincide
    }else {

      next(); // Si está autenticado, permite el acceso
    }
  } else {
    if (to.path === '/login' && store.getters.isAuthenticated) {
      // Si el usuario está autenticado y trata de ir a /login, redirige a la página de inicio
      next('/eventos');
    } else {
      // Permite el acceso si la ruta no requiere autenticación y no es /login
      next();
    }
  }
});

export default router
