<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Incapacidades</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link to="/addIncapacidades" id="agregar" class="btn btn-info pull-left"
                                    style="margin-top: -7px; margin-left: 2px">Crear Incapacidad
                                    <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table id="colaboradores" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th class="text-center">Colaborador</th>
                                <th class="text-center">Desde</th>
                                <th class="text-center">Hasta</th>
                                <th class="text-center">Dias</th>
                                <th class="text-center">Motivo</th>
                                <th class="text-center">Comentarios</th>
                                <th class="text-center">Archivo</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="incapacidad in datos" :key="incapacidad.id">
                                <td class="text-center">{{ incapacidad.nombre }}</td>
                                <td class="text-center">{{ incapacidad.desde }}</td>
                                <td class="text-center">{{ incapacidad.hasta }}</td>
                                <td class="text-center">{{ incapacidad.dias }}</td>
                                <td class="text-center">{{ incapacidad.motivo }}</td>
                                <td class="text-center">{{ incapacidad.comentarios }}</td>
                                <td class="text-center">
                                    <a target="_blank" class="pdf"
                                        :href="`${filebaseurl}/incapacidades/${incapacidad.archivo}`" alt="">Archivo</a>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group" role="group">
                                        <router-link :to="{
                                            name: 'editIncapacidades',
                                            params: { id: incapacidad.id },
                                        }" class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                                        <button class="btn btn-danger" @click="
                                            deleteIncapacidad(
                                                incapacidad.id
                                            )
                                            ">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()">
                                <a class="page-link" href="#">
                                    </a>
                            </li>
                            <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                                v-bind:key="isActive(pagina)">
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            filebaseurl:process.env.VUE_APP_FILES_BASE_URL,
            incapacidades: [],
            pageSize: 5,
            datos: [],
            paginaActual: 1,
            listLength: 0,
        };
    },
    created() {
        this.axios.get("/api/incapacidades", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.incapacidades = response.data;
            this.listLength = this.incapacidades.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deleteIncapacidad(id) {
            Swal.fire({
                title: "¿Desea continuar?",
                confirmButtonColor: "red",
                showCancelButton: true,
                confirmButtonText: "Delete",
                icon: "error",
            }).then((res) => {
                if (res.value) {
                    this.axios
                        .delete(`/api/incapacidades/${id}`, {
                            headers: {
                                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                            }
                        })
                        .then((response) => {
                            let i = this.incapacidades
                                .map((data) => data.id)
                                .indexOf(id);
                            this.incapacidades.splice(i, 1);
                            this.reload();
                        });
                }
            });
        },
        totalPaginas() {
            return Math.ceil(this.incapacidades.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.incapacidades.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
