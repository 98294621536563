<template>
  <div class="row">
    <div class="col-md-offset-1 col-md-12">
      <div class="panel">
        <div class="panel-heading">
          <div class="row">
            <div class="col-3 text-start">
              <div
                v-show="
                  userRole === 'Administrador' ||
                  userRole === 'Nivel1' ||
                  userRole === 'SuperAdministrador'
                "
              >
                <div class="btn_group">
                  <router-link to="/addProveedor" class="btn btn-info"
                    >Nuevo <i class="fas fa-plus"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-9">
              <h3 class="title">Proveedores</h3>
            </div>
          </div>
        </div>
        <NoExist v-if="listLength == 0" />
        <div class="panel-body table-responsive" v-if="listLength != 0">
          <input type="text" v-model="busqueda" id='busqueda' class="form-control"
              placeholder="Nombre" />
          <table class="table table-bordered table-striped table-condensed">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Razón Social</th>
                <th>Plazo</th>
                <th>Teléfono</th>
                <th>Correo</th>
                <th>Cuenta</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="proveedor in searchProveedor" :key="proveedor.id">
                <td>{{ proveedor.nombre }}</td>
                <td>{{ proveedor.razon_social }}</td>
                <td>{{ proveedor.plazo }}</td>
                <td>{{ proveedor.contacto_Telefonico }}</td>
                <td>{{ proveedor.contacto_Correo }}</td>
                <td>{{ proveedor.formas_pago_Cuenta }}</td>
                <td>
                  <div class="btn-group" role="group">
                    <router-link :to="{ name: 'editProveedor', params: { id: proveedor.id } }"
                      class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                    <button class="btn btn-danger" @click="deleteProveedor(proveedor.id)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-if="listLength > pageSize"
            :totalPages="totalPaginas()"
            :currentPage="paginaActual"
            @getPreviousPage="getPreviousPage"
            @getNextPage="getNextPage"
            @getDataPagina="getDataPagina"
            @isActive="isActive"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
function buscarEnObjecto(objecto, palabra) {
  for (let key in objecto) {
    if (objecto.hasOwnProperty(key) && objecto[key].toString().toLowerCase().includes(palabra)) {
      return true;
    }
  }

  return false;
}
import NoExist from "../Shared/NoExist.vue";
import Pagination from "../Shared/Pagination.vue";
export default {

  inject: ['reload'],
  components: {
    NoExist,
    Pagination
  },
  data() {
    return {
      proveedores: [],
      pageSize: 10,
      datos: [],
      paginaActual: 1,
      busqueda: '',
      listLength: 0
    };
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
    searchProveedor: function () {
      if (this.busqueda.length > 0) {
        this.datos = this.proveedores;
        this.pageSize = 10000;
      } else {
        this.pageSize = 10;
        this.getDataPagina(1);
      }
      return this.datos.filter((proveedor) => {
        return buscarEnObjecto(proveedor, this.busqueda.toLowerCase());
      });
    }
  },
  created() {
    this.axios.get("/api/proveedores", {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
      }
    })
      .then((response) => {
        this.proveedores = response.data;
        this.listLength = this.proveedores.length;
        this.getDataPagina(1);
      });
  },
  methods: {
    deleteProveedor(id) {
      this.axios
        .delete(`/api/proveedores/${id}`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then((response) => {
          let i = this.proveedores.map((data) => data.id).indexOf(id);
          this.proveedores.splice(i, 1);
          this.reload();
        });
      Swal.fire({
        title: 'Eliminado!',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500
      })
    },
    totalPaginas() {
      return Math.ceil(this.proveedores.length / this.pageSize)
    },
    getDataPagina(noPagina) {
      this.paginaActual = noPagina;
      this.datos = [];
      let x = noPagina - 1;
      let ini = x * this.pageSize;
      let fin = noPagina * this.pageSize;
      this.datos = this.proveedores.slice(ini, fin);
    },
    getPreviousPage() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage() {
      if (this.paginaActual < this.totalPaginas()) {
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina) {
      return noPagina == this.paginaActual ? 'active' : '';
    }
  },
};
</script>