<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Presupuestos</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                              <router-link to="/addPresupuesto"  id="agregar"  class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear Presupuesto <i class="fas fa-plus"></i></router-link>
                             </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table class="table table-bordered table-striped table-condensed" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Nombre</th>
                            <th>Inicio</th>
                            <th>Fin</th>
                            <th>Organización</th>
                            <th>Monto</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="presupuesto in datos" :key="presupuesto.id">
                        <td>{{ presupuesto.nombre }}</td>
                        <td>{{ presupuesto.fecha_Inicio }}</td>
                        <td>{{ presupuesto.fecha_Fin }}</td>
                        <td>{{ cargarOrganizacion(presupuesto.organizacion)}}</td>
                        <td>₡ {{ presupuesto.monto }}</td>
                        <td>
                        <div class="btn-group" role="group">

                            <router-link
                            :to="{ name: 'editPresupuesto', params: { id: presupuesto.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deletePresupuesto(presupuesto.id)"
                            >
                            <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"><</a></li>
                      <li v-for="pagina in totalPaginas()" v-bind:key="isActive(pagina)" v-on:click="getDataPagina(pagina)" class="page-item"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
  inject:['reload'],
    components: {
		  NoExist
	},
  data() {
    return {
      presupuestos: [],
      organizaciones: [],
      pageSize:10,
      datos:[],
      paginaActual:1,
      listLength:0
    };
  },
  created() {
    this.axios.get("/api/presupuestos", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.presupuestos = response.data.presupuestos;
      this.organizaciones = response.data.organizaciones;
      this.listLength = this.presupuestos.length;
       this.getDataPagina(1);
    });
  },
   methods: {
    deletePresupuesto(id) {
      this.axios.delete(`/api/presupuestos/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          var resultado = response.data.resultado['resultado'];
          let i = this.presupuestos.map((data) => data.id).indexOf(id);
          this.presupuestos.slice(i,1),
          this.reload();

          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Eliminado!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
          else{
            Swal.fire({
            title: 'No se puede Eliminar',
            text: 'El presupuesto tiene egresos asociados',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })
          }
        });
    },
    totalPaginas(){
       return Math.ceil(this.presupuestos.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.presupuestos.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     },
     cargarOrganizacion(id){
       var nombres = '';
       for(var key in this.organizaciones){
         for(var i in this.organizaciones[key]){
          if(this.organizaciones[key].id == id){

            nombres = this.organizaciones[key].nombre;
          }
         }
       }
       return nombres;
    },
  },
};
</script>