<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">
                        Registro de días feriados
                    </h3>
                    <form @submit.prevent="addFeriados">
                        <div class="row">
                            <div class="form-group col">
                                <label>Fecha</label>
                                <input type="date" class="form-control" v-model="feriado.fecha" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Motivo</label>
                                <input type="text" class="form-control" v-model="feriado.motivo" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label>Comentarios</label>
                                <textarea type="text" class="form-control" v-model="feriado.comentarios"
                                    rows="3"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btn btn-success">
                                    Agregar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            feriados: [],
            feriado: {},
        };
    },
    created() {
        // this.axios
        //     .get(`/api/feriadosempleados/${this.$route.params.id}`, {
        //         headers: {
        //             'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        //         }
        //     })
        //     .then((response) => {
        //         this.feriados = response.data.feriados;
        //     });
    },
    methods: {
        addFeriados() {
            this.axios
                .post("/api/feriadosempleados", this.feriado, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => location.reload())
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Día feriado registrado!",
                showConfirmButton: false,
                timer: 2000,
            });
        },
    },
};
</script>
