
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Recetas</h3>
      <form @submit.prevent="addRecetas">
      <div class="form-group">
        <label>Medicamento</label>
        <input
          type="text"
          class="form-control"
          v-model="receta.medicamento"
          />
      </div>
      <div class="form-group">
        <label>Dosis</label>
        <input
          type="text"
          class="form-control"
          v-model="receta.dosis"
        />
      </div>
      <div class="form-group">
        <label>Frecuencia</label>
        <input
          type="text"
          class="form-control"
          v-model="receta.frecuencia"
        />
      </div>
      <div class="form-group">
        <label>Estado</label>
        <select class="form-control" v-model="receta.estado">
          <option value="cronicas">Crónicas</option>
          <option value="agudo">Agudo</option>
          <option value="de ser necesario">De ser necesario</option>
        </select>
      </div>
      <button  type="submit" class="btn btn-success">Agregar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      recetas: [],
      joven:{},
      receta:{'joven': ''},
    };
  },
  created() {
      this.axios
      .get(`/api/recetas/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.recetas = response.data.recetas;
      this.joven = response.data.joven;
    });
  },
   methods: {
    addRecetas() {
      this.receta.joven = this.joven.id;
      this.axios
        .post("/api/recetas", this.receta, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => location.reload())
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
        Swal.fire({
        icon: 'success',
        title: 'Receta Creada!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>