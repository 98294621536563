<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-11">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Usuarios</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link to="/addUsuario" id="agregar" class="btn btn-info pull-left">Crear Usuario
                                    <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table id="usuarios" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th class="text-center">Nombre</th>
                                <th class="text-center">Correo</th>
                                <th class="text-center">Contraseña</th>
                                <th class="text-center">Rol</th>
                                <th class="text-center">Fecha de ingreso</th>
                                <!-- <th class="text-center">Vacaciones disponibles</th> -->
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="usuario in datos" :key="usuario.id">
                                <td class="text-center">{{ usuario.name }}</td>
                                <td class="text-center">{{ usuario.email }}</td>
                                <td class="text-center">
                                    <i class="fas fa-eye"></i>
                                </td>
                                <td class="text-center">{{ usuario.role }}</td>
                                <td class="text-center">
                                    {{ usuario.fecha_ingreso }}
                                </td>
                                <!-- <td class="text-center">{{ usuario.dias_disponibles}} días</td> -->
                                <td>
                                    <div class="btn-group" role="group">
                                        <router-link :to="{
                                            name: 'editUsuario',
                                            params: { id: usuario.id },
                                        }" class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                                        <button class="btn btn-danger" @click="deleteUsuario(usuario.id)">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()">
                                <a class="page-link" href="#">
                                    </a>
                            </li>
                            <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                                v-bind:key="isActive(pagina)">
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            usuarios: [],
            pageSize: 5,
            datos: [],
            paginaActual: 1,
            listLength: 0,
        };
    },
    created() {
        this.axios.get("/api/usuarios", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.usuarios = response.data;
            this.listLength = this.usuarios.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        deleteUsuario(id) {
            this.axios.delete(`/api/usuario/${id}`,{headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }}).then((response) => {
                let i = this.usuarios.map((data) => data.id).indexOf(id);
                this.usuarios.splice(i, 1);
                this.reload();
            });
            Swal.fire({
                title: "Eliminado!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.usuarios.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.usuarios.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
