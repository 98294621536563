
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Cirugias</h3>
      <form @submit.prevent="updateCirugias">
      <div class="form-group">
        <label>Fecha</label>
        <input
          type="date"
          class="form-control"
          v-model="cirugia.fecha"
          />
      </div>
      <div class="form-group">
        <label>Nombre</label>
        <input
          type="text"
          class="form-control"
          v-model="cirugia.nombre"
        />
      </div>
      <div class="form-group">
        <label>Especialista</label>
        <input
          type="text"
          class="form-control"
          v-model="cirugia.especialista"
        />
      </div>
      <div class="form-group">
        <label>Categoria</label>
          <select class="form-control" v-model="cirugia.categoria">
            <option value="Grave">Grave</option>
            <option value="Leve">Leve</option>
          </select>
      </div>
      <div class="form-group">
        <label>Descripción</label>
        <input
          type="text"
          class="form-control"
          v-model="cirugia.descripcion"
        />
      </div>
      <button  type="submit" class="btn btn-success">Editar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      cirugia:{},
    };
  },
  created() {
      this.axios
      .get(`/api/cirugias/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.cirugia = response.data.cirugia;
    });
  },
   methods: {
    updateCirugias() {
      this.axios
        .patch(
          `/api/cirugias/${this.$route.params.id}`,
          this.cirugia, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        )
        .then((res) => {
          this.$router.go(0);
        });
        Swal.fire({
        icon: 'success',
        title: 'Cirugia Actualizada!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>