<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div
                            class="col col-sm-6 col-xs-10"
                            style="padding-right: 0px"
                        >
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                            <h3 class="title">Citas de {{ joven.nombre }}</h3>
                        </div>
                        <div class="col-sm-6 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link
                                    :to="{
                                        name: 'addCitas',
                                        params: { id: joven.id },
                                    }"
                                    id="agregar"
                                    class="btn btn-info pull-left"
                                    style="margin-top: -7px; margin-left: 2px"
                                    >Agregar <i class="fas fa-plus"></i
                                ></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                    >
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Nombre del Especialista</th>
                                <th>Especialidad</th>
                                <th>Detalle</th>
                                <th>Imágen</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cita in datos" :key="cita.id">
                                <td>{{ cita.fecha }}</td>
                                <td>{{ cita.nombre }}</td>
                                <td>{{ cita.especialista }}</td>
                                <td>{{ cita.detalle }}</td>
                                <td><a v-if="cita.foto" :href="`${filebaseurl}/jovenes/citas${cita.foto}`" target="_blank">
                                    <img
                                        class="imagenF"
                                        width="100"
                                        height="100"
                                        :src="`${filebaseurl}/jovenes/citas${cita.foto}`"
                                        alt=""
                                    /></a>
                                </td>
                                <td>
                                    <div class="btn-group" role="group">
                                        <router-link
                                            :to="{
                                                name: 'editCitas',
                                                params: { id: cita.id },
                                            }"
                                            class="btn btn-success"
                                            ><i class="fas fa-edit"></i
                                        ></router-link>
                                        <button
                                            class="btn btn-danger"
                                            @click="deleteCitas(cita.id)"
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item">
                                <a class="page-link" href="" @click.prevent="getPreviousPage()"></a>
                            </li>
                            <li
                                v-for="pagina in totalPaginas()"
                                class="page-item"
                                v-bind:key="isActive(pagina)"
                            >
                                <a class="page-link"  href="" @click.prevent="getDataPagina(pagina)">{{ pagina }}</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link" href="" @click.prevent="getNextPage()">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ["reload"],
    data() {
        return {
            filebaseurl:process.env.VUE_APP_FILES_BASE_URL,
            citas: [],
            joven: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
        };
    },
    created() {
        this.axios
            .get(`/api/jovenes/${this.$route.params.id}/citas`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.citas = response.data.citas;
                this.joven = response.data.joven;
                this.getDataPagina(1);
            });
    },
    methods: {
        deleteCitas(id) {
            this.axios.delete(`/api/citas/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                let i = this.citas.map((data) => data.id).indexOf(id);
                this.citas.splice(i, 1);
                this.reload();
            });
            Swal.fire({
                title: "Eliminada!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
            });
        },
        totalPaginas() {
            return Math.ceil(this.citas.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.citas.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
