<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Asociados</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                                <router-link to="/addAsociado"  id="agregar" class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear Asociado <i class="fas fa-plus"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                <NoExist v-if="listLength == 0"/>

                    <table  id="asociados" class="table table-striped" v-if="listLength != 0">
                        <thead>
                            <tr>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Teléfono</th>

                            <th>Modo pago</th>
                            <th>Periodo</th>
                            <th>Fecha último pago</th>
                            <th>Monto</th>



                            <th>Correo</th>
                            <th>Dirección</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="asesor in datos" :key="asesor.id">
                        <td>{{ asesor.nombre }}</td>
                        <td>{{ asesor.apellido }}</td>
                        <td>{{ asesor.telefono}}</td>

                        <td>{{ asesor.modo}}</td>
                        <td>{{ asesor.periodo}}</td>
                        <td>{{ asesor.fecha_ultimo_pago}}</td>
                        <td>{{ formatPrice(asesor.monto) }}</td>


                        <td>{{ asesor.correo}}</td>
                        <td>{{ asesor.direccion}}</td>
                        <td v-if="asesor.estado == 1">Activo</td>
                        <td v-else>Inactivo</td>
                        <td>
                          <div class="btn-group" role="group">
                              <router-link :to="{ name: 'editAsociado', params: { id: asesor.id } }" class="btn btn-success">
                                <i class="fas fa-edit"></i>
                              </router-link>
                              <button class="btn btn-danger" @click="deleteAsesor(asesor.id)" >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                              <router-link :to="{ name: 'recibosAsociado', params: { id: asesor.id } }" class="btn btn-success">
                                <i class="fas fa-file"></i>
                              </router-link>
                          </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                      <ul class="pagination justify-content-center">
                        <li class="page-item" v-on:click="getPreviousPage()">
                          <a class="page-link" href="#"></a>
                        </li>
                        <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)">
                          <a class="page-link" href="#">{{pagina}}</a>
                        </li>
                        <li class="page-item" v-on:click="getNextPage()">
                          <a class="page-link" href="#"></a>
                        </li>
                      </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      asociados: [],
      pageSize:5,
      datos:[],
      paginaActual:1,
      listLength:0
    };
  },
  created() {
    this.axios.get("/api/asociados", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.asociados = response.data;
      this.listLength = this.asociados.length;
      this.getDataPagina(1);
    });
  },
   methods: {
    deleteAsesor(id) {
      console.log(id);
        Swal.fire({
          title:'¿Desea eliminar al asociado?',
          confirmButtonColor:"red",
          showCancelButton:true,
          confirmButtonText:"Delete",
          icon:"error"
    }).then(res =>{
      if (res.value) {
          this.axios
          .delete(`/api/asociados/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
          .then((response) => {
          let i = this.asociados.map((data) => data.id).indexOf(id);
          this.asociados.splice(i, 1);
          this.reload();
        });
      }
    })
    },
       formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    totalPaginas(){
       return Math.ceil(this.asociados.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.asociados.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     }
  },
};
</script>