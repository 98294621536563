<template>
  <div class="container">
    <div class="row px-3">
      <div class="col-lg-12 col-xl-12 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></a>
          <h3 class="title text-center mt-4">Crear Eventos</h3>
          <form @submit.prevent="addEventos">
            <div class="row">
              <div class="form-group col-lg-4 col-sm-12">
                <label>Tipo de Evento</label>
                <select class="form-control" v-model="evento.tipo" required>
                  <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.id"
                    @click="cargarPuntos(tipo.id)">
                    {{tipo.nombre}}
                  </option>
                </select>
              </div>
              <div class="form-group col-lg-8 col-sm-12">
                <label>Joven</label>
                <select class="form-control" v-model="evento.joven" required>
                  <option v-for="joven in jovenes" :key="joven.id" :value="joven.id">
                    {{joven.nombre}}
                  </option>
                </select>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="form-group col-lg-4 col-sm-12">
                <label>Día</label>
                <input type="date" class="form-control" v-model="evento.fecha" required :max="today" />
              </div>
              <div class="form-group col-lg-4 col-sm-12">
                <label>Hora</label>
                <input type="time" class="form-control" v-model="evento.hora" required />
              </div>
              <div class="form-group col-lg-4 col-sm-12">
                <label>Puntos</label>
                <input type="text" id="puntos" name="puntos" class="form-control" v-model="evento.puntaje" />
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="form-group col-lg-8 col-sm-12">
                <label>Descripción</label>
                <textarea class="form-control" required ref="desc" v-model="evento.descripcion"></textarea>
                <div class="hidden">
                  <button @click="startRecording" v-show="!isRecording" style="background: transparent; border: none;">
                    <i class="fa fa-microphone" style="color: #861473;"></i>
                  </button>
                  <button @click="sendAudio" v-show="isRecording" style="background: transparent; border: none;">
                    <i class="fa fa-stop" style="color: #861473;"></i>
                  </button>
                </div>
              </div>
              <div class="form-group col-lg-4 col-sm-12">
                <label>Documento: </label>
                <a class="text-dark" v-show="evento && evento.documento" :href="`${filebaseurl}/${evento.documento}`"
                    target="_blank">Ver</a>
                <input type="file" class="" @change="obtenerDocumento" />
              </div>

            </div>
            <button type="submit" class="btn btn-success">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const date = new Date();
const today = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
export default {
  data() {
    return {
      filebaseurl: process.env.VUE_APP_DOCUMENTS_BASE_URL,
      eventos: [],
      today: today,
      evento: { 'puntaje': '', 'descripcion': '', documento: null },
      jovenes: [],
      joven: {},
      tipos: [],
      //Audio recording
      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],
      recordedBlob: null,
      //File
      documento: {
        nombre: '',
        stock: 0,
        archivo: '',
      }
    };
  },
  created() {
    if (this.$route.params.id) {
      this.axios
        .get(`/api/eventos/${this.$route.params.id}`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then((response) => {
          this.evento = response.data.evento;
          this.tipos = response.data.tipos;
          this.jovenes = response.data.jovenes;

        });
    } else {
      this.axios
        .get(`/api/eventos/new`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then((response) => {
          this.tipos = response.data.tipos;
          this.jovenes = response.data.jovenes;
          this.tipos = this.editarTipos(this.tipos);
        });
    }
  },
  methods: {
    startRecording() {
      const constraints = { audio: true };
      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = event => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          };
          this.mediaRecorder.start();
          this.isRecording = true;
        })
        .catch(error => {
          console.error('Error al comenzar a grabar:', error);
        });
    },
    async sendAudio() {

      this.mediaRecorder.stop();
      this.isRecording = false;

      if (this.recordedChunks.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No pudimos detectar el audio.',
        })
        return;
      } else {
        const loadingAlert = Swal.fire({
          title: 'Procesando audio...',
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        // Convertir fragmentos grabados en un Blob
        this.recordedBlob = new Blob(this.recordedChunks, { type: 'audio/wav' });
        try {
          const response = await axios.post('/api/transcribe', this.recordedBlob, {
            headers: {
              'Content-Type': 'application/octet-stream',
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
          });

          this.evento.descripcion = response.data?.text;
          this.recordedChunks = null;
          loadingAlert.close();

        } catch (error) {
          console.error('Error al enviar el audio:', error);
        }
      }

    },

    editarTipos(tipos) {
      var variable = "Jóvenes";
      var tipos1 = [];
      for (var key in tipos) {
        if (tipos[key].categoria == variable) {
          tipos1.push(tipos[key]);
        }
      }
      return tipos1;
    },
    obtenerDocumento(event) {
      console.log("entra a obtener documento");
      let file = event.target.files[0];
      this.documento.archivo = file;
      console.log("esto es lo que tiene el documento seteado ", this.documento);
    },
    addEventos() {
      let formData = new FormData();
      formData.append('nombre', this.evento.nombre);
      formData.append('joven', this.evento.joven);
      formData.append('tipo', this.evento.tipo);
      formData.append('puntaje', document.getElementById("puntos").value);
      formData.append('fecha', this.evento.fecha);
      formData.append('hora', this.evento.hora);
      formData.append('descripcion', this.evento.descripcion);
      formData.append('documento', this.documento.archivo);

      // if updating
      if (this.$route.params.id) {
        formData.append("_method", "PATCH");
        this.axios
          .post(
            `/api/eventos/${this.$route.params.id}`,
            formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            },
          }
          )
          .then((res) => {
            Swal.fire({
              icon: 'success',
              title: 'Evento Actualizado!',
              showConfirmButton: false,
              timer: 1500
            }).then((alertResult) => {
              this.$router.go(-1);
              this.$router.push({ name: 'citas', params: { id: this.joven.id } });
            });

          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Hubo un error al actualizar el evento!',
              showConfirmButton: false,
              timer: 1500
            })
            console.log(err);
          });

      } else {

        this.axios
          .post("/api/eventos", formData, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        })
          .then((response) => {
            Swal.fire({
              icon: 'success',
              title: 'Evento Creado!',
              showConfirmButton: false,
              timer: 1500
            }).then((alertResult) => {
              this.$router.push({ name: 'eventos' });
              this.loading = false
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Hubo un error al crear el evento!',
              showConfirmButton: false,
              timer: 1500
            })
            console.log(err);
          })
          .finally(() => (this.loading = false));
      }

    },
    cargarPuntos(id) {
      var nombres = '';
      for (var key in this.tipos) {
        for (var i in this.tipos[key]) {
          if (this.tipos[key].id == id) {
            nombres = this.tipos[key].puntos;
          }
        }
      }

      return $("#puntos").val(nombres);

    },

  },
};
</script>