var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-container"},[_c('button',{staticClass:"toggleBtn",on:{"click":_vm.toggleSidebar}},[_c('i',{staticClass:"icon fa fa-bars"})]),_c('nav',{staticClass:"sidebar",style:(_vm.sidebarDispose),attrs:{"id":"sidebar"}},[_c('ul',[_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel1' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel1' ||\n          userRole === 'SuperAdministrador'\n        "}],staticClass:"dropdown"},[_vm._m(0),_c('div',{staticClass:"dropdown-content"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/eventos' })}}},[_c('i',{staticClass:"icon fa-solid fa-calendar-week"}),_vm._v(" Eventos ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/tipos' })}}},[_c('i',{staticClass:"icon fa-solid fa-calendar-days"}),_vm._v(" Tipos ")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.userRole === 'Nivel1' || _vm.userRole === 'Nivel2'),expression:"userRole === 'Nivel1' || userRole === 'Nivel2'"}],staticClass:"dropdown"},[_vm._m(1),_c('div',{staticClass:"dropdown-content"},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/vacacionesempleados' })}}},[_c('i',{staticClass:"icon fa-solid fa-person-walking-luggage"}),_vm._v(" Control de Vacaciones ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/permisosempleados' })}}},[_c('i',{staticClass:"icon fa-solid fa-user-check"}),_vm._v(" Permisos ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/horasextrasempleados' })}}},[_c('i',{staticClass:"icon fa-solid fa-user-clock"}),_vm._v(" Horas Extras ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/feriadosempleados' })}}},[_c('i',{staticClass:"icon fa-solid fa-calendar-days"}),_vm._v(" Días Feriados ")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' || _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' || userRole === 'SuperAdministrador'\n        "}],staticClass:"dropdown"},[_vm._m(2),_c('div',{staticClass:"dropdown-content"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/vacacionesadm' })}}},[_c('i',{staticClass:"icon fa-solid fa-person-walking-luggage"}),_vm._v(" Control de Vacaciones ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/permisosadm' })}}},[_c('i',{staticClass:"icon fa-solid fa-user-check"}),_vm._v(" Control de Permisos ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/incapacidades' })}}},[_c('i',{staticClass:"icon fa-solid fa-head-side-mask"}),_vm._v(" Incapacidades ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/horasextras' })}}},[_c('i',{staticClass:"icon fa-solid fa-user-clock"}),_vm._v(" Horas Extras ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/feriados' })}}},[_c('i',{staticClass:"icon fa-solid fa-calendar-days"}),_vm._v(" Días Feriados ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/recibocolaborador' })}}},[_c('i',{staticClass:"icon fa-solid fa-money-check-dollar"}),_vm._v(" Generar Recibos ")]),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/aguinaldos' })}}},[_c('i',{staticClass:"icon fa-solid fa-money-bill-trend-up"}),_vm._v(" Aguinaldos ")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' || _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' || userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/presupuestos' })}}},[_c('i',{staticClass:"icon fas fa-hand-holding-usd"}),_c('span',[_vm._v("Presupuestos")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel2' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel2' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/egresos' })}}},[_c('i',{staticClass:"icon fas fa-funnel-dollar"}),_c('span',[_vm._v("Egresos")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel1' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel1' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/jovenes' })}}},[_c('i',{staticClass:"icon fas fa-child"}),_c('span',[_vm._v("PMEs")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel2' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel2' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/proveedores' })}}},[_c('i',{staticClass:"icon fas fa-people-carry"}),_c('span',[_vm._v("Proveedores")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' || _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' || userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/usuarios' })}}},[_c('i',{staticClass:"icon fas fa-user"}),_c('span',[_vm._v("Usuarios")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel2' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel2' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/colaboradores' })}}},[_c('i',{staticClass:"icon fas fa-users"}),_c('span',[_vm._v("Colaboradores")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel1' ||
          _vm.userRole === 'Nivel2' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel1' ||\n          userRole === 'Nivel2' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/menuReportes' })}}},[_c('i',{staticClass:"icon fas fa-file-alt"}),_c('span',[_vm._v("Reportes")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel2' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel2' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/asociados' })}}},[_c('i',{staticClass:"icon fa-solid fa-people-group"}),_c('span',[_vm._v("Asociados")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' ||
          _vm.userRole === 'Nivel2' ||
          _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' ||\n          userRole === 'Nivel2' ||\n          userRole === 'SuperAdministrador'\n        "}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/recibos' })}}},[_c('i',{staticClass:"icon fa-solid fa-receipt"}),_c('span',[_vm._v("Recibos")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.userRole === 'Administrador' || _vm.userRole === 'SuperAdministrador'
        ),expression:"\n          userRole === 'Administrador' || userRole === 'SuperAdministrador'\n        "}],staticClass:"dropdown"},[_vm._m(3),_c('div',{staticClass:"dropdown-content"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$router.push({ path: '/oficinas' })}}},[_c('i',{staticClass:"icon fa-solid fa-building"}),_vm._v(" Oficinas Locales ")])])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.userRole === 'SuperAdministrador'),expression:"userRole === 'SuperAdministrador'"}]},[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":function($event){return _vm.$router.push({ path: '/empresas' })}}},[_c('i',{staticClass:"icon fa-solid fa-city"}),_c('span',[_vm._v("Empresas")])])])]),_c('ul',{staticClass:"secondary-nav"},[_c('li',[_c('a',{staticClass:"s-sidebar__nav-link",on:{"click":_vm.logout}},[_c('i',{staticClass:"icon fa-solid fa-lock"}),_c('span',[_vm._v("Cerrar Sesión")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icon fas fa-x fa-calendar-week"}),_c('span',[_vm._v("Eventos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icon fa-solid fa-people-group"}),_c('span',[_vm._v("Recursos Humanos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"s-sidebar__nav-link",attrs:{"href":"#","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"icon fa-solid fa-people-group"}),_c('span',[_vm._v("Recursos Humanos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"s-sidebar__nav-link",attrs:{"href":"#","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"icon fa-solid fa-gear"}),_c('span',[_vm._v("Configuraciones")])])
}]

export { render, staticRenderFns }