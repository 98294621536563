<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col">
                            <a
                                @click="$router.go(-1)"
                                class="btn btn-info pull-right"
                                id="volver"
                                ><i class="fas fa-arrow-alt-circle-left"></i
                            ></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col" v-if="listLength != 0">
                            <label id="reportes">Joven</label>
                            <select
                                id="joven"
                                style="
                                    width: 200px;
                                    height: 29px;
                                    border-radius: 5px;
                                "
                            >
                                <option
                                    id="datos"
                                    v-for="joven in jovenes"
                                    :key="joven.id"
                                    :value="joven.id"
                                    v-text="joven.nombre"
                                ></option>
                            </select>
                        </div>
                        <div class="col">
                            <label>Fecha Inicio</label>
                            <input
                                type="date"
                                id="fecha_inicio"
                                style="
                                    border-radius: 5px;
                                    width: 200px;
                                    height: 29px;
                                "
                            />
                        </div>
                        <div class="col">
                            <label>Fecha Final</label>
                            <input
                                type="date"
                                id="fecha_final"
                                style="
                                    border-radius: 5px;
                                    width: 200px;
                                    height: 29px;
                                "
                            />
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                class="btn btn-info"
                                style="width: 150px"
                                @click="buscar()"
                            >
                                Consultar <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div class="col" style="margin-top: 1.6%">
                            <button
                                id="agregar1"
                                style="
                                    background-color: #fec107;
                                    border-color: #fec107;
                                    width: 150px;
                                "
                                class="btn btn-danger"
                                @click="generateReport()"
                            >
                                Descargar PDF
                                <i class="fa-solid fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table
                        class="
                            table table-bordered table-striped table-condensed
                        "
                        v-if="listLength != 0"
                    >
                        <thead>
                            <tr>
                                <th class="text-center">Categoria</th>
                                <th class="text-center">Tipo</th>
                                <th class="text-center">Puntaje</th>
                                <th class="text-center">Descripcion</th>
                                <th class="text-center">Dia</th>
                                <th class="text-center">Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="reporteL in datos" :key="reporteL.id">
                                <td class="text-center">
                                    {{ reporteL.categoria }}
                                </td>
                                <td class="text-center">{{ reporteL.tipo }}</td>
                                <td class="text-center">
                                    {{ reporteL.puntaje }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.descripcion }}
                                </td>
                                <td class="text-center">
                                    {{ reporteL.fecha }}
                                </td>
                                <td class="text-center">{{ reporteL.hora }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <VueHtml2pdf
                        :show-layout="false"
                        :float-layout="true"
                        :enable-download="true"
                        :preview-modal="true"
                        :paginate-elements-by-height="1400"
                        filename="Reporte Eventos"
                        :pdf-quality="2"
                        :manual-pagination="false"
                        pdf-format="a4"
                        pdf-orientation="landscape"
                        pdf-content-width="1000px"
                        ref="html2Pdf"
                    >
                        <section
                            slot="pdf-content"
                            style="
                                color: black;
                                padding-left: 15%;
                                padding-top: 4%;
                            "
                            class="
                                align-items-center
                                justify-content-center
                                text-center
                            "
                        >
                            <h2 class="text-center" style="margin-bottom: 4%;">
                                Reporte de eventos
                            </h2>
                            <div class="panel-body table-responsive">
                                <NoExist v-if="listLength == 0" />
                                <table
                                    class="
                                        table
                                        table-bordered
                                        table-striped
                                        table-condensed
                                    "
                                    v-if="listLength != 0"
                                >
                                    <thead>
                                        <tr>
                                            <th class="text-center">
                                                Categoria
                                            </th>
                                            <th class="text-center">Tipo</th>
                                            <th class="text-center">Puntaje</th>
                                            <th class="text-center">
                                                Descripcion
                                            </th>
                                            <th class="text-center">Dia</th>
                                            <th class="text-center">Hora</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="reporteL in datos"
                                            :key="reporteL.id"
                                        >
                                            <td class="text-center">
                                                {{ reporteL.categoria }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.tipo }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.puntaje }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.descripcion }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.fecha }}
                                            </td>
                                            <td class="text-center">
                                                {{ reporteL.hora }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import NoExist from "../Shared/NoExist.vue";
import VueHtml2pdf from "vue-html2pdf";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        VueHtml2pdf,
    },
    data() {
        return {
            jovenes: [],
            eventos: [],
            periodo: [],
            datos: {},
            listLength: 0,
        };
    },
    created() {
        this.axios.get(`/api/reportes/eventos`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
            this.jovenes = response.data.jovenes;
            this.eventos = response.data.eventos;
            this.periodos = response.data.periodos;
            this.listLength = this.datos.length;
        });
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf();
        },
        buscar() {
            var joven = document.getElementById("joven").value;
            var fecha_inicio = document.getElementById("fecha_inicio").value;
            var fecha_final = document.getElementById("fecha_final").value;
            var aux = new Array();
            for (var key in this.eventos) {
                if (this.eventos[key].joven == joven) {
                    if (
                        this.eventos[key].created_at >= fecha_inicio &&
                        this.eventos[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            categoria: "",
                            tipo: "",
                            puntaje: "",
                            descripcion: "",
                            fecha: "",
                            hora: "",
                        };
                        aux2.categoria = this.eventos[key].categoria;
                        aux2.tipo = this.eventos[key].tipo;
                        aux2.puntaje = this.eventos[key].puntaje;
                        aux2.descripcion = this.eventos[key].descripcion;
                        aux2.fecha = this.eventos[key].fecha;
                        aux2.hora = this.eventos[key].hora;
                        var fecha = new Date(this.eventos[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux.push(aux2);
                    }
                }
            }
            for (var key in this.periodos) {
                if (this.periodos[key].joven == joven) {
                    if (
                        this.periodos[key].created_at >= fecha_inicio &&
                        this.periodos[key].created_at <= fecha_final
                    ) {
                        var aux2 = {
                            nombre: "",
                            categoria: "",
                            detalle: "",
                            fecha: "",
                        };
                        aux2.nombre = "";
                        aux2.categoria = "Periodo";
                        var fecha = new Date(this.periodos[key].created_at);
                        aux2.fecha =
                            fecha.getUTCFullYear() +
                            "-" +
                            fecha.getUTCMonth() +
                            "-" +
                            fecha.getUTCDate();
                        aux2.detalle = "Registrado";
                        aux.push(aux2);
                    }
                }
            }

            this.datos = aux;
        },
    },
};
</script>
