<template>
  <div class="sidebar-container">
    <button @click="toggleSidebar" class="toggleBtn">
      <i class="icon fa fa-bars"></i>
    </button>
    <nav class="sidebar" id="sidebar" :style="sidebarDispose">
      <!-- Botones del Sidebar -->
      <ul>
        <!-- DropDown Eventos -->
        <li
          class="dropdown"
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel1' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a href="#">
            <i class="icon fas fa-x fa-calendar-week"></i>
            <span>Eventos</span>
          </a>
          <div class="dropdown-content">
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/eventos' })"
            >
              <i class="icon fa-solid fa-calendar-week"></i>
              Eventos
            </a>
            <a class="dropdown-item" @click="$router.push({ path: '/tipos' })">
              <i class="icon fa-solid fa-calendar-days"></i>
              Tipos
            </a>
          </div>
        </li>
        <!-- DropDown Recursos Humanos n1 y n2 -->
        <li
          class="dropdown"
          v-show="userRole === 'Nivel1' || userRole === 'Nivel2'"
        >
          <!-- Boton -->
          <a href="#">
            <i class="icon fa-solid fa-people-group"></i>
            <span>Recursos Humanos</span>
          </a>
          <div class="dropdown-content">
            <a
              class="s-sidebar__nav-link"
              @click="$router.push({ path: '/vacacionesempleados' })"
            >
              <i class="icon fa-solid fa-person-walking-luggage"></i>
              Control de Vacaciones
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/permisosempleados' })"
            >
              <i class="icon fa-solid fa-user-check"></i>
              Permisos
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/horasextrasempleados' })"
            >
              <i class="icon fa-solid fa-user-clock"></i>
              Horas Extras
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/feriadosempleados' })"
            >
              <i class="icon fa-solid fa-calendar-days"></i>
              Días Feriados
            </a>
          </div>
        </li>
        <!-- DropDown Recursos Humanos admin-->
        <li
          class="dropdown"
          v-show="
            userRole === 'Administrador' || userRole === 'SuperAdministrador'
          "
        >
          <!-- Boton -->
          <a
            class="s-sidebar__nav-link"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="icon fa-solid fa-people-group"></i>
            <span>Recursos Humanos</span>
          </a>
          <div class="dropdown-content">
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/vacacionesadm' })"
            >
              <i class="icon fa-solid fa-person-walking-luggage"></i>
              Control de Vacaciones
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/permisosadm' })"
            >
              <i class="icon fa-solid fa-user-check"></i>
              Control de Permisos
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/incapacidades' })"
            >
              <i class="icon fa-solid fa-head-side-mask"></i>
              Incapacidades
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/horasextras' })"
            >
              <i class="icon fa-solid fa-user-clock"></i>
              Horas Extras
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/feriados' })"
            >
              <i class="icon fa-solid fa-calendar-days"></i>
              Días Feriados
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/recibocolaborador' })"
            >
              <i class="icon fa-solid fa-money-check-dollar"></i>
              Generar Recibos
            </a>
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/aguinaldos' })"
            >
              <i class="icon fa-solid fa-money-bill-trend-up"></i>
              Aguinaldos
            </a>
          </div>
        </li>

        <li
          v-show="
            userRole === 'Administrador' || userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/presupuestos' })"
          >
            <i class="icon fas fa-hand-holding-usd"></i
            ><span>Presupuestos</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel2' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/egresos' })"
          >
            <i class="icon fas fa-funnel-dollar"></i>
            <span>Egresos</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel1' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/jovenes' })"
          >
            <i class="icon fas fa-child"></i>
            <span>PMEs</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel2' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/proveedores' })"
          >
            <i class="icon fas fa-people-carry"></i>
            <span>Proveedores</span>
          </a>
        </li>
        <li
          v-show="
            userRole === 'Administrador' || userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/usuarios' })"
          >
            <i class="icon fas fa-user"></i>
            <span>Usuarios</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel2' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/colaboradores' })"
          >
            <i class="icon fas fa-users"></i>
            <span>Colaboradores</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel1' ||
            userRole === 'Nivel2' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/menuReportes' })"
          >
            <i class="icon fas fa-file-alt"></i>
            <span>Reportes</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel2' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/asociados' })"
          >
            <i class="icon fa-solid fa-people-group"></i>
            <span>Asociados</span>
          </a>
        </li>

        <li
          v-show="
            userRole === 'Administrador' ||
            userRole === 'Nivel2' ||
            userRole === 'SuperAdministrador'
          "
        >
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/recibos' })"
          >
            <i class="icon fa-solid fa-receipt"></i>
            <span>Recibos</span>
          </a>
        </li>
        <!-- DropDown Configuraciones -->
        <li
          class="dropdown"
          v-show="
            userRole === 'Administrador' || userRole === 'SuperAdministrador'
          "
        >
          <!-- Boton -->
          <a
            class="s-sidebar__nav-link"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="icon fa-solid fa-gear"></i>
            <span>Configuraciones</span>
          </a>

          <div class="dropdown-content">
            <a
              class="dropdown-item"
              @click="$router.push({ path: '/oficinas' })"
            >
              <i class="icon fa-solid fa-building"></i>
              Oficinas Locales
            </a>
          </div>
        </li>
        <li v-show="userRole === 'SuperAdministrador'">
          <a
            class="s-sidebar__nav-link"
            @click="$router.push({ path: '/empresas' })"
          >
            <i class="icon fa-solid fa-city"></i>
            <span>Empresas</span>
          </a>
        </li>
      </ul>
      <ul class="secondary-nav">
        <li>
            <a class="s-sidebar__nav-link " @click="logout">
                <i class="icon fa-solid fa-lock"></i><span>Cerrar Sesión</span>
            </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSidebarVisible: true,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    sidebarDispose() {
      return {
        display: this.isSidebarVisible ? "block" : "none",
      };
    },
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push({ name: 'Login' }); // Redirigir al usuario a la página de login
    },

    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
  },
};
</script>
<style scoped lang="scss">
.sidebar-container {
  background-color: var(--color-primario);
  z-index: 20;
}
.sidebar {
  padding: 0px 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in;
  width: 18em;
  transition: width 0.3s, display 0.3s;
}
ul.secondary-nav {
    background-color: var(--color-secundario);
}
.sidebar ul {
  /*  */
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  /* */
  margin: 10px 0;
}

.sidebar li span {
  display: inline;
  padding-left: 1%;
  font-size: large;
}
.sidebar li a {
  cursor: pointer;
  svg {
    width: 16px;
    margin-right: 10px;
  }
  &:hover {
    font-weight: 600;
  }
}

.sidebar > ul > li > a {
  text-decoration: none;
  color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--color-secundario);
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Estilos para los botones del dropdown */
.dropdown-item {
  color: white;
  display: block;
  width: 100%;
  align-items: center;
  justify-content: normal;
  padding-top: 6px;
  padding-bottom: 6px;
}

.dropdown-item:hover {
  color: white;
}

.toggleBtn {
  background-color: var(--color-primario);
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
}

.icon {
  font-size: 1.25rem;
}

/* Responsive styles */
@media (max-width: 1030px) {
  .icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
    .sidebar-container{
        position: absolute;
        top: 0;
    }
  .toggleBtn {
    display: block;
  }
}

@media (min-width: 768px) {
  .sidebar {
    width: 225px !important;
  }
  .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem;
    width: 225px;
  }
  .sidebar .nav-item .nav-link span {
    font-size: 1rem;
    display: inline;
  }
  .sidebar .nav-item .dropdown-menu {
    position: static !important;
    margin: 0 1rem;
    top: 0;
  }
  .sidebar .nav-item.dropdown .dropdown-toggle::after {
    display: block;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 90px !important;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 90px;
  }
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  .sidebar.toggled .nav-item .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    transform: none !important;
    left: calc(90px + 0.5rem) !important;
    margin: 0;
  }
  .sidebar.toggled .nav-item .dropdown-menu.dropup {
    bottom: 0;
    top: auto !important;
  }
  .sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
    display: none;
  }
}

/* Tablet*/
@media (min-width: 42em) {
  .toggleBtn {
    display: block;
  }
  .s-layout {
    width: 2em;
    top: 120px;
  }
  .s-layout__content {
    margin-left: 2em;
  }

  /* Sidebar */

  .s-sidebar__nav {
    width: 35px;
    left: 0;
  }

  .s-sidebar__nav:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    width: 15em;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-brand {
    width: 30%;
  }
  .navbar-brand .logo {
    width: 100%;
  }
}
</style>
