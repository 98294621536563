<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-3 text-start">
                            <div v-show="userRole === 'Administrador' || userRole === 'Nivel1' || userRole === 'SuperAdministrador'">
                                <div class="btn_group">
                                    <router-link to="/addEventos" class="btn btn-info">Nuevo <i class="fas fa-plus"></i></router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <h3 class="title">Eventos</h3>
                        </div>
                    </div>
                    <hr>
                    <div class="row" v-if="this.$route.params.jovenId">
                        <div class="col">
                            <p class="text-dark">
                                Eventos para {{ this.eventos[0].nombre }}.
                                <router-link class="text-dark" :to="{ name: 'eventos' }">Ver todos</router-link>
                            </p>
                        </div>
                    </div>
                    <div class="row filter-container" v-else>
                        <div class="col-sm-3">
                            <label>Tipo</label>
                            <select class="form-control" v-model="tipoFilter" name="tipoFilter" id="tipoFilter"
                                v-on:change="loadEvents">
                                <option v-for="tipo in this.tipos" :key="tipo.id" :value="tipo.id">
                                    {{ tipo.nombre }}
                                </option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <label>Joven</label>
                            <input type="text" v-model="nombreJoven" class="form-control" v-on:keyup="loadEvents" />
                        </div>
                        <div class="col-sm-3">
                            <label>Fecha Inicio</label>
                            <input type="date" v-model="startDate" class="form-control" required
                                pattern="\d{4}-\d{2}-\d{2}" v-on:change="loadEvents" />
                        </div>
                        <div class="col-sm-3">
                            <label>Fecha Fin</label>
                            <input type="date" v-model="endDate" class="form-control" pattern="\d{4}-\d{2}-\d{2}" v-on:change="loadEvents" />
                        </div>
                    </div>
                    <hr>
                </div>
                <NoExist v-if="listLength == 0" />
                <div class="panel-body table-responsive" v-if="listLength != 0">
                    <div class="col-12">
                        <input type="text" v-model="busqueda" id="busqueda" class="form-control" placeholder="Filtrar lista" />
                    </div>
                    <table class="table table-bordered table-striped table-condensed">
                        <thead>
                            <tr>
                                <th>Tipo</th>
                                <th>Joven</th>
                                <th>Puntaje</th>
                                <th>Descripción</th>
                                <th>Día</th>
                                <th>Hora</th>
                                <th class="text-center"><i class="fa-solid fa-gear "></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="evento in searchEvento" :key="evento.id">
                                <td>{{ evento.tipo }}</td>
                                <td>{{ evento.nombre }}</td>
                                <td>{{ evento.puntaje }}</td>
                                <td>{{ evento.descripcion }}</td>
                                <td>{{ evento.fecha }}</td>
                                <td>{{ evento.hora }}</td>
                                <td class="text-center">
                                    <div class="btn-group" role="group"  v-show="userRole === 'Administrador' || userRole === 'Nivel1' || userRole === 'SuperAdministrador'">
                                        <router-link :to="{
                                            name: 'editEventos',
                                            params: { id: evento.id },
                                        }" class="btn btn-success"><i class="fas fa-edit"></i></router-link>
                                        <button class="btn btn-danger" @click="
                                            deleteEventos(evento.id)
                                            ">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination
                        v-if="listLength > 10"
                        :listLength="listLength"
                        :paginaActual="paginaActual"
                        :getNextPage="getNextPage"
                        :totalPaginas="totalPaginas"
                        :getPreviousPage="getPreviousPage"
                        :isActive="isActive"
                        :getDataPagina="getDataPagina"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import NoExist from "../Shared/NoExist.vue";
import Pagination from "../Shared/Pagination.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
        Pagination,
    },
    data() {
        return {
            eventos: [],
            pageSize: 10,
            datos: [],
            jovenes: [],
            tipos: [],
            paginaActual: 1,
            busqueda: "",
            listLength: 0,
            startDate: new Date(Date.now() - 12096e5).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            nombreJoven: '',
            tipoFilter: ''
        };
    },
    created() {
        if (this.$route.params.jovenId) {
            this.axios.get(`/api/eventos?jovenId=${this.$route.params.jovenId}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    this.eventos = response.data.eventos;
                    this.tipos = response.data.tipos;
                    this.jovenes = response.data.jovenes;
                    this.listLength = this.eventos.length;
                    this.getDataPagina(1);
                }).catch(error => {
                    console.log(error);
                });
        } else {
            this.axios.get(`/api/eventos?startDate=${this.startDate}&endDate=${this.endDate}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.eventos = response.data.eventos;
                this.tipos = response.data.tipos;
                this.jovenes = response.data.jovenes;
                this.listLength = this.eventos.length;
                this.getDataPagina(1);
            });
        }
    },
    computed: {
        searchEvento: function () {
            return this.datos.filter((eventos) => {
                for (let key in eventos) {
                    if (
                        eventos.hasOwnProperty(key) &&
                        eventos[key].toString().toLowerCase().includes(this.busqueda.toLowerCase())
                    ) {
                        return true;
                    }
                }
                return false;
            });
        },
        userRole() {
            return this.$store.getters.getUserRole;
        }
    },
    methods: {
        deleteEventos(id) {
            Swal.fire({
                title: "¿Desea continuar?",
                confirmButtonColor: "red",
                showCancelButton: true,
                confirmButtonText: "Delete",
                icon: "error",
            }).then((res) => {
                if (res.value) {
                    this.axios.delete(`/api/eventos/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then((response) => {
                        let i = this.eventos.map((data) => data.id).indexOf(id);
                        this.eventos.splice(i, 1);
                        this.reload();
                    });
                }
            });
        },
        totalPaginas() {
            return Math.ceil(this.eventos.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.eventos.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
        cargarTipo(id) {
            var nombres = "";
            for (var key in this.tipos) {
                for (var i in this.tipos[key]) {
                    if (this.tipos[key].id == id) {
                        nombres = this.tipos[key].nombre;
                    }
                }
            }
            return nombres;
        },
        cargarJoven(id) {
            var nombres = "";
            for (var key in this.jovenes) {
                for (var i in this.jovenes[key]) {
                    if (this.jovenes[key].id == id) {
                        nombres = this.jovenes[key].nombre;
                    }
                }
            }
            return nombres;
        },
        loadEvents: function () {
            this.axios.get(`/api/eventos?tipo=${this.tipoFilter}&startDate=${this.startDate}&endDate=${this.endDate}&jovenNombre=${this.nombreJoven}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                this.eventos = response.data.eventos;
                this.tipos = response.data.tipos;
                this.jovenes = response.data.jovenes;
                this.listLength = this.eventos.length;
                this.getDataPagina(1);
            });
        }
    },
};
</script>
