
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <router-link to="/presupuestos" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
        <h3 class="title text-center mt-4">Presupuestos</h3>
      <form @submit.prevent="addPresupuesto">
      <div class="form-group">
        <label>Nombre</label>
        <input
          type="text"
          class="form-control"
          v-model="presupuesto.nombre"
          />
      </div>
      <div class="form-group">
        <label>Fecha de Inicio</label>
        <input
          type="date"
          class="form-control"
          v-model="presupuesto.fecha_Inicio"
        />
      </div>
      <div class="form-group" >
        <label>Fecha de Fin</label>
        <input
          type="date"
          class="form-control"
          v-model="presupuesto.fecha_Fin"
        />
      </div>
      <div class="form-group">
        <label>Organización</label>
        <select class="form-control" v-model="presupuesto.organizacion">
         <option v-for="organizacion in organizaciones" :key="organizacion.id" :value="organizacion.id" v-text="organizacion.nombre">
           {{organizacion.nombre}}
         </option>
        </select>
      </div>
      <div class="form-group" >
        <label>Monto</label>
        <input
          placeholder="₡"
          type="text"
          id="number"
          class="form-control"
          v-model="presupuesto.monto"
        />
      </div>
      <button  type="submit" class="btn btn-success">Agregar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>


export default {
  inject:['reload'],
  data() {
    return {
      presupuestos: [],
      organizaciones:[],
      presupuesto:{},
      organizacion:{},
    };
  },
  created() {
    this.axios.get("/api/presupuestos", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.presupuestos = response.data.presupuestos;
      this.organizaciones = response.data.organizaciones;
      console.log(response.data)
    });
  },
  methods: {
    addPresupuesto() {
      this.axios
        .post("/api/presupuestos", this.presupuesto, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Ingrese todos los datos',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
          else{
            Swal.fire({
            icon: 'success',
            title: 'Presupuesto Creado!',
            showConfirmButton: false,
            timer: 1500
            })
            this.reload();

          }

        });

    },
    numeros(){
    $("#number").on({
      "focus": function(event) {
        $(event.target).select();
      },
      "keyup": function(event) {
        $(event.target).val(function(index, value) {
          return value.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "");
        });
      }
    });
    },
  },
};
</script>