<template>
  <div class="container">
    <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Rubro</h3>
          <form @submit.prevent="updateRubro">
            <div class="form-group" >
              <label>Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="rubro.nombre_rubro"
              />
            </div>
            <div class="form-group" >
              <label>Alias</label>
              <input
                type="text"
                class="form-control"
                v-model="rubro.alias_rubro"
              />
            </div>
            <div class="form-group" >
              <label>Monto</label>
              <input
                type="text"
                placeholder="₡"
                id="number"
                class="form-control"
                v-model="rubro.monto_rubro"
              />
            </div>
            <button type="submit" class="btn btn-success">Editar</button>
          </form>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  inject:['reload'],
  data() {
    return {
      rubro:{},
    };

  },
  created() {
    this.axios
      .get(`/api/presupuestos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((res) => {
        this.rubro = res.data.rubro;
      });
  },
  methods: {
    updateRubro() {
      this.axios
        .patch(
          `/api/rubros/${this.$route.params.id}`,
          this.rubro, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }
        ).then((response) => {
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Ingrese todos los datos',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
          else{
            Swal.fire({
            icon: 'success',
            title: 'Rubro Actualizado!',
            showConfirmButton: false,
            timer: 1500

          })
          this.reload();
          }

        });

    },
     numeros(){
    $("#number").on({
      "focus": function(event) {
        $(event.target).select();
      },
      "keyup": function(event) {
        $(event.target).val(function(index, value) {
          return value.replace(/\D/g, "")
            .replace(/([0-7])([0-7]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "");
        });
      }
    });
    },
  },
};
</script>