<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/usuarios" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Usuarios</h3>
                    <form @submit.prevent="updateUsuario">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" v-model="usuario.name" />
                        </div>
                        <div class="form-group">
                            <label>Correo</label>
                            <input type="email" class="form-control" v-model="usuario.email" />
                        </div>
                        <div class="form-group">
                            <label>Contraseña</label>
                            <input type="password" class="form-control" v-model="usuario.password" />
                        </div>
                        <div class="form-group">
                            <label>Rol</label>
                            <select class="form-control" v-model="usuario.role">
                                <option value="Administrador">
                                    Administrador
                                </option>
                                <option value="Nivel1">Nivel 1</option>
                                <option value="Nivel2">Nivel 2</option>
                            </select>
                        </div>
                        <button type="submit" class="btn btn-success">Editar</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ["reload"],
    data() {
        return {
            usuario: {},
        };
    },
    created() {
        this.axios.get(`/api/usuario/${this.$route.params.id}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((res) => {
            this.usuario = res.data.user;
        });
    },
    methods: {
        updateUsuario() {
            this.axios
                .patch(`/api/usuario/${this.$route.params.id}`, this.usuario, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    var resultado = response.data.resultado["resultado"];
                    var x = parseInt(resultado[0]) === parseInt(1);
                    if (x) {
                        Swal.fire({
                            title: "Ingrese todos los datos",
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Usuario Actualizado!",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.$router.push({ name: "usuarios" });
                    }
                });
        },
    },
};
</script>
