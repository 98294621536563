<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <a @click= "$router.go(-1)" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></a>
        <h3 class="title text-center mt-4">Nuevo Rubro</h3>
      <form @submit.prevent="addRubro">
        <div class="form-group">
        <label>Presupuesto</label>
        <input
          type="text"
          class="form-control"
          disabled
          v-model="presupuesto.nombre"
          />
      </div>
      <div class="form-group">
        <label>Nombre del rubro</label>
        <input
          type="text"
          class="form-control"
          v-model="rubro.nombre_rubro"
          />
      </div>
      <div class="form-group">
        <label>Alias</label>
        <input
          type="text"
          class="form-control"
          v-model="rubro.alias_rubro"
          />
      </div>
      <div class="form-group" >
        <label>Monto</label>
        <input
          type="text"
          id="number"
          class="form-control"
          v-model="rubro.monto_rubro"
        />
      </div>
      <button  type="submit" class="btn btn-success">Agregar</button>
    </form>
  </div>
    </div>
  </div>
</div>
</template>

<script>


export default {
  inject:['reload'],
  data() {
    return {
      presupuesto:{},
      rubro:{'ID_Presupuesto': ''},
    };
  },
  created() {
    this.axios
    .get(`/api/presupuestos/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
        this.presupuesto= response.data.presupuesto;
    });
  },
  methods: {
    addRubro() {
      this.rubro.ID_Presupuesto = this.presupuesto.id;
      this.axios
        .post("/api/rubros", this.rubro, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) =>{
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Ingrese todos los datos',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
          else{
            Swal.fire({
            icon: 'success',
            title: 'Rubro Creado!',
            showConfirmButton: false,
            timer: 1500
          })
            this.reload();

          }

        });
    },
    numeros(){
    $("#number").on({
      "focus": function(event) {
        $(event.target).select();
      },
      "keyup": function(event) {
        $(event.target).val(function(index, value) {
          return value.replace(/\D/g, "")
            .replace(/([0-9])([0-9]{2})$/, '$1.$2')
            .replace(/\B(?=(\d{5})+(?!\d)\.?)/g, ".")
        });
      }
    });
    },
  },

};
</script>