<template>
  <div class="row">
    <div class="col-md-offset-1 col-md-12 px-0">
      <div class="panel">
        <div class="panel-heading">
          <div class="row">
            <div class="col-3 text-start">
              <div
                v-show="
                  userRole === 'Administrador' ||
                  userRole === 'Nivel1' ||
                  userRole === 'SuperAdministrador'
                "
              >
                <div class="btn_group">
                  <router-link
                    to="/addTipos"
                    id="agregar"
                    class="btn btn-info pull-left"
                    >Nuevo <i class="fas fa-plus"></i
                  ></router-link>
                </div>
              </div>
            </div>
            <div class="col-9">
              <h3 class="title">Tipos de Eventos</h3>
            </div>
          </div>
        </div>
        <NoExist v-if="listLength == 0" />
        <div class="panel-body table-responsive" v-if="listLength != 0">
          <table class="table table-bordered table-striped table-condensed">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Puntos</th>
                <th>Tipo</th>
                <th>Categoria</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tipo in datos" :key="tipo.id">
                <td>{{ tipo.nombre }}</td>
                <td>{{ tipo.puntos }}</td>
                <td>{{ tipo.tipo }}</td>
                <td>{{ tipo.categoria }}</td>
                <td>
                  <div class="btn-group" role="group">
                    <router-link
                      :to="{ name: 'editTipos', params: { id: tipo.id } }"
                      class="btn btn-success"
                      ><i class="fas fa-edit"></i
                    ></router-link>
                    <button
                      class="btn btn-danger"
                      @click="deleteTipos(tipo.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            v-if="listLength > 10"
            :listLength="listLength"
            :paginaActual="paginaActual"
            :getNextPage="getNextPage"
            :totalPaginas="totalPaginas"
            :getPreviousPage="getPreviousPage"
            :isActive="isActive"
            :getDataPagina="getDataPagina"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoExist from "../../Shared/NoExist.vue";
import Pagination from "../../Shared/Pagination.vue";
export default {
  inject: ["reload"],
  components: {
    NoExist,
    Pagination,
  },
  data() {
    return {
      tipos: [],
      pageSize: 10,
      datos: [],
      paginaActual: 1,
      listLength: 0,
    };
  },
  created() {
    this.axios
      .get(`/api/tipos`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        this.tipos = response.data;
        this.listLength = this.tipos.length;
        this.getDataPagina(1);
      });
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
  },
  methods: {
    deleteTipos(id) {
      Swal.fire({
        title: "¿Desea continuar?",
        confirmButtonColor: "red",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "error",
      }).then((res) => {
        if (res.value) {
          this.axios
            .delete(`/api/tipos/${id}`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              let i = this.tipos.map((data) => data.id).indexOf(id);
              this.tipos.splice(i, 1);
              this.reload();
            });
        }
      });
    },
    totalPaginas() {
      return Math.ceil(this.tipos.length / this.pageSize);
    },
    getDataPagina(noPagina) {
      this.paginaActual = noPagina;
      this.datos = [];
      let x = noPagina - 1;
      let ini = x * this.pageSize;
      let fin = noPagina * this.pageSize;
      this.datos = this.tipos.slice(ini, fin);
    },
    getPreviousPage() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
      this.getDataPagina(this.paginaActual);
    },
    getNextPage() {
      if (this.paginaActual < this.totalPaginas()) {
        this.paginaActual++;
      }
      this.getDataPagina(this.paginaActual);
    },
    isActive(noPagina) {
      return noPagina == this.paginaActual ? "active" : "";
    },
  },
};
</script>
