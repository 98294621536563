<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Horas Extras</h4>
                        </div>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                    <NoExist v-if="listLength == 0" />
                    <table class="
                            table table-bordered table-striped table-condensed
                        " v-if="listLength != 0">
                        <thead>
                            <tr>
                                <th class="text-center">Usuario</th>
                                <th class="text-center">Día</th>
                                <th class="text-center">Hora de entrada</th>
                                <th class="text-center">Hora de salida</th>
                                <th class="text-center">Cantidad de horas</th>
                                <th class="text-center">Comentarios</th>
                                <th class="text-center">Revisado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="horaextra in searchHorasExtra" :key="horaextra.id">
                                <td class="text-center">
                                    {{ horaextra.name }}
                                </td>
                                <td class="text-center">
                                    {{ horaextra.fecha }}
                                </td>
                                <td class="text-center">
                                    {{ horaextra.hora_inicio }}
                                </td>
                                <td class="text-center">
                                    {{ horaextra.hora_fin }}
                                </td>
                                <td class="text-center">
                                    {{ horaextra.cantidad_horas }}
                                </td>
                                <td class="text-center">
                                    {{ horaextra.comentarios }}
                                </td>
                                <td class="text-center">
                                    <input type="checkbox" id="estado" @click="updateEstado(horaextra.id)"
                                        v-model="horaextra.estado" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example" v-if="listLength != 0">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" v-on:click="getPreviousPage()">
                                <a class="page-link" href="#"></a>
                            </li>
                            <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
                                v-bind:key="isActive(pagina)">
                                <a class="page-link" href="#">{{ pagina }}</a>
                            </li>
                            <li class="page-item" v-on:click="getNextPage()">
                                <a class="page-link" href="#">></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
function buscarEnObjecto(objecto, palabra) {
    for (let key in objecto) {
        if (
            objecto.hasOwnProperty(key) &&
            objecto[key].toString().toLowerCase().includes(palabra)
        ) {
            return true;
        }
    }

    return false;
}
import NoExist from "../Shared/NoExist.vue";
export default {
    inject: ["reload"],
    components: {
        NoExist,
    },
    data() {
        return {
            horasextras: [],
            pageSize: 10,
            datos: [],
            paginaActual: 1,
            busqueda: "",
            listLength: 0,
        };
    },
    computed: {
        searchHorasExtra: function () {
            return this.datos.filter((horaextra) => {
                return buscarEnObjecto(horaextra, this.busqueda.toLowerCase());
            });
        },
    },
    created() {
        this.axios.get("/api/horasextras", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.horasextras = response.data;
            this.listLength = this.horasextras.length;
            this.getDataPagina(1);
        });
    },
    methods: {
        updateEstado(id) {
            var checkbox = document.getElementById("estado");
            checkbox.addEventListener("change", validaCheckbox, false);
            function validaCheckbox() {
                var checked = checkbox.checked;
                if (checked) {
                    // alert("checkbox1 esta seleccionado");
                }
            }
            this.axios.patch(`/api/horasextras/${id}`, {
                // Los datos que deseas enviar en el cuerpo de la solicitud PATCH
                // Aquí deberías incluir los datos que deseas actualizar
            }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((res) => {
                // Manejo de la respuesta
            }).catch((error) => {
                console.error(error);
            });
        },
        totalPaginas() {
            return Math.ceil(this.horasextras.length / this.pageSize);
        },
        getDataPagina(noPagina) {
            this.paginaActual = noPagina;
            this.datos = [];
            let x = noPagina - 1;
            let ini = x * this.pageSize;
            let fin = noPagina * this.pageSize;
            this.datos = this.horasextras.slice(ini, fin);
        },
        getPreviousPage() {
            if (this.paginaActual > 1) {
                this.paginaActual--;
            }
            this.getDataPagina(this.paginaActual);
        },
        getNextPage() {
            if (this.paginaActual < this.totalPaginas()) {
                this.paginaActual++;
            }
            this.getDataPagina(this.paginaActual);
        },
        isActive(noPagina) {
            return noPagina == this.paginaActual ? "active" : "";
        },
    },
};
</script>
