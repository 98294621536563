<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <router-link to="/usuarios" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></router-link>
                    <h3 class="title text-center mt-4">Usuarios</h3>
                    <form @submit.prevent="addUsuario">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input type="text" class="form-control" v-model="usuario.name" />
                        </div>
                        <div class="form-group">
                            <label>Correo</label>
                            <input id="email" type="email" class="form-control" v-model="usuario.email" />
                        </div>
                        <div class="form-group">
                            <label>Contraseña</label>
                            <input type="password" class="form-control" v-model="usuario.password" />
                        </div>
                        <div class="form-group">
                            <label>Rol</label>
                            <select class="form-control" v-model="usuario.role">
                                <option value="Administrador">
                                    Administrador
                                </option>
                                <option value="Nivel1">Nivel 1</option>
                                <option value="Nivel2">Nivel 2</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Fecha de ingreso</label>
                            <input type="date" class="form-control" v-model="usuario.fecha_ingreso" />
                        </div>
                        <div class="form-group">
                            <label>Dias de vacaciones disponibles del periodo anterior</label>
                            <input type="number" class="form-control" v-model="usuario.total_dias" min="0" value="0" />
                        </div>

                        <button type="submit" class="btn btn-success">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { exportDefaultSpecifier } from "@babel/types";

export default {
    inject: ["reload"],
    data() {
        return {
            usuarios: [],
            usuario: {},
        };
    },
    created() {
        this.axios.get("/api/usuarios", {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            this.usuarios = response.data;
        });
    },
    methods: {
        addUsuario() {
            this.axios.post("/api/usuario", this.usuario, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then((response) => {
                var resultado = response.data.resultado["resultado"];
                var x = parseInt(resultado[0]) === parseInt(1);
                var y = parseInt(resultado[0]) === parseInt(3);
                if (x) {
                    Swal.fire({
                        title: "Ingrese todos los datos",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
                if (y) {
                    Swal.fire({
                        title: "La fecha de ingreso no puede ser mayor a la fecha actual.",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: "Usuario Creado!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.reload();
                }
            });
        },
    },
};
</script>
