<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
                            class="fas fa-arrow-alt-circle-left"></i></a>
                    <h3 class="title text-center mt-4">Evolución Medica</h3>
                    <form @submit.prevent="addEvolucion">
                        <div class="form-group">
                            <label>Fecha</label>
                            <input type="date" class="form-control" v-model="evolucion.fecha" />
                        </div>
                        <div class="form-group">
                            <label>Diagnóstico</label>
                            <input type="text" class="form-control" v-model="evolucion.diagnostico" />
                        </div>
                        <div class="form-group">
                            <label>Especialista</label>
                            <input type="text" class="form-control" v-model="evolucion.especialista" />
                        </div>
                        <div class="form-group">
                            <label>Archivo</label>
                            <input type="file" class="form-control" @change="obtenerImagen" />
                        </div>
                        <button type="submit" class="btn btn-success">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            evoluciones: [],
            joven: {},
            evolucion: { joven: "" },
            imagen: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
        };
    },
    created() {
        this.axios
            .get(`/api/evoluciones/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.evoluciones = response.data.evoluciones;
                this.joven = response.data.joven;
            });
    },
    methods: {
        obtenerImagen(event) {
            let file = event.target.files[0];
            this.imagen.archivo = file;
            this.cargarImagen(file);
        },
        addEvolucion() {
            let formData = new FormData();
            formData.append("archivo", this.imagen.archivo);
            formData.append("diagnostico", this.evolucion.diagnostico);
            formData.append("fecha", this.evolucion.fecha);
            formData.append("especialista", this.evolucion.especialista);
            formData.append("joven", this.joven.id);
            this.axios
                .post("/api/evoluciones", formData, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                    }
                })
                .then((response) => location.reload())
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
            Swal.fire({
                icon: "success",
                title: "Evaluación Médica Creada!",
                showConfirmButton: false,
                timer: 1500,
            });
        },
    },
};
</script>
