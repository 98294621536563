<template>
    <div class="row">
        <div class="col-md-offset-1 col-md-12">
            <div class="panel" id="grande">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col col-sm-3 col-xs-12">
                            <h4 class="title">Egresos</h4>
                        </div>
                        <div class="col-sm-9 col-xs-12 text-right">
                            <div class="btn_group">
                              <router-link to="/addEgreso"  id="agregar"  class="btn btn-info pull-left" style="margin-top:-7px;margin-left:2px;">Crear Egreso <i class="fas fa-plus"></i></router-link>
                             </div>
                        </div>
                        <input type="text" v-model="busqueda" id='busqueda' class="form-control" placeholder="Buscar" v-if="listLength != 0"/>
                    </div>
                </div>
                <div class="panel-body table-responsive">
                  <NoExist v-if="listLength == 0"/>
                    <table class="table table-bordered table-striped table-condensed"  v-if="listLength != 0">
                        <thead>

                            <tr>
                            <th>Presupuesto</th>
                            <th>Rubro</th>
                            <th>Alias</th>
                            <th>Proveedor</th>
                            <th>Tipo</th>
                            <th>Estado</th>
                            <th>Número Documento</th>
                            <th>Detalle</th>
                            <th>Fecha Documento</th>
                            <th>Vencimiento</th>
                            <th>Monto</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="egreso in searchEgreso" :key="egreso.id">
                        <td>{{ cargarPresupuesto(egreso.presupuesto) }}</td>
                        <td>{{ cargarRubro(egreso.rubro_Presupuesto) }}</td>
                        <td>{{ egreso.alias_rubro_Presupuesto }}</td>
                        <td>{{ cargarProveedor(egreso.proveedor) }}</td>
                        <td>{{ egreso.tipo}}</td>
                        <td>{{ egreso.estado}}</td>
                        <td>{{ egreso.numero_documento}}</td>
                        <td>{{ egreso.detalle}}</td>
                        <td>{{ egreso.fecha_documento}}</td>
                        <td>{{ egreso.fecha_vencimiento}}</td>
                        <td>₡{{ egreso.monto}}</td>
                        <td>
                        <div class="btn-group" role="group" >
                          <!-- <div class="btn-group" role="group" v-if="egreso.estado =='Pendiente'"> -->
                          <div class="btn-group" role="group" v-if="rolUsuario =='Administrador'">
                            <router-link
                            :to="{ name: 'editEgreso', params: { id: egreso.id } }"
                            class="btn btn-success"
                            ><i class="fas fa-edit"></i></router-link>
                            <button
                            class="btn btn-danger"
                            @click="deleteEgreso(egreso.id)"
                            >
                            <i class="fas fa-times"></i>
                            </button>
                          </div>


                        </div>
                        </td>
                            </tr>
                    </tbody>
                    </table>
                    <nav aria-label="Page navigation example"  v-if="listLength != 0">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" v-on:click="getPreviousPage()"><a class="page-link" href="#"></a></li>
                      <li  v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item" v-bind:key="isActive(pagina)"><a class="page-link" href="#">{{pagina}}</a></li>
                      <li class="page-item" v-on:click="getNextPage()"><a class="page-link" href="#">></a></li>
                    </ul>
                  </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

function buscarEnObjecto(objecto, palabra) {
  for (let key in objecto) {
    if (objecto.hasOwnProperty(key) && objecto[key].toString().toLowerCase().includes(palabra) ) {
      return true;
    }
  }

  return false;
}
import NoExist from "../Shared/NoExist.vue";
export default {

  inject:['reload'],
  components: {
		  NoExist
	},
  data() {
    return {
      rolUsuario: "",
      egresos: [],
      rubros: [],
      presupuestos: [],
      proveedores: [],
      pageSize:10,
      datos:[],
      paginaActual:1,
      busqueda: '',
      listLength:0
    };
  },
  computed:{
    searchEgreso: function () {
      return this.datos.filter((egreso) => {
          return buscarEnObjecto(egreso,this.busqueda.toLowerCase());
      });
    }
  },
  created() {
    this.axios.get("/api/egresos", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.rolUsuario = response.data.rolUsuario;
      this.egresos = response.data.egresos;
      this.presupuestos = response.data.presupuestos
      this.proveedores = response.data.proveedores
      this.rubros = response.data.rubros
      this.listLength = this.egresos.length;
      this.getDataPagina(1);
    });
  },
   methods: {
    deleteEgreso(id) {
                          Swal.fire({
          title:'¿Desea continuar?',
          confirmButtonColor:"red",
          showCancelButton:true,
          confirmButtonText:"Delete",
          icon:"error"
    }).then(res =>{
      if (res.value) {
      this.axios
        .delete(`/api/egresos/${id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          let i = this.egresos.map((data) => data.id).indexOf(id);
          this.reload();
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'Egreso Anulado',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })

          }
        });
      }
    });



    },
    totalPaginas(){
       return Math.ceil(this.egresos.length / this.pageSize)
     },
     getDataPagina(noPagina){
       this.paginaActual = noPagina;
       this.datos=[];
       let x = noPagina-1;
       let ini = x * this.pageSize;
       let fin = noPagina * this.pageSize;
       this.datos = this.egresos.slice(ini,fin);
     },
     getPreviousPage(){
       if(this.paginaActual>1){
         this.paginaActual--;
       }
       this.getDataPagina(this.paginaActual);
     },
     getNextPage(){
       if(this.paginaActual<this.totalPaginas()){
         this.paginaActual++;
       }
       this.getDataPagina(this.paginaActual);
     },
     isActive(noPagina){
       return noPagina == this.paginaActual ? 'active':'';
     },
     cargarPresupuesto(id){
       var nombres = '';
       for(var key in this.presupuestos){
         for(var i in this.presupuestos[key]){
          if(this.presupuestos[key].id == id){

            nombres = this.presupuestos[key].nombre;
          }
         }
       }
       return nombres;
    },
    cargarProveedor(id){
       var nombres = '';
       for(var key in this.proveedores){
         for(var i in this.proveedores[key]){
          if(this.proveedores[key].id == id){

            nombres = this.proveedores[key].nombre;
          }
         }
       }
       return nombres;
    },
    cargarRubro(id){
       var nombres = '';
       for(var key in this.rubros){
         for(var i in this.rubros[key]){
          if(this.rubros[key].id == id){

            nombres = this.rubros[key].nombre_rubro;
          }
         }
       }
       return nombres;
    },
  },
};
</script>