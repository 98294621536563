<template>
  <div class="container">
    <div class="row px-3 aqui">
      <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
        <div class="card-body">
          <a @click="$router.go(-1)" class="btn btn-info pull-right" id="volver"><i
              class="fas fa-arrow-alt-circle-left"></i></a>
          <h3 class="title text-center mt-4">Dolencias</h3>
          <form @submit.prevent="updateDolencia">
            <div class="form-group">
              <label>Fecha</label>
              <input type="date" class="form-control" v-model="dolencia.fecha" />
            </div>
            <div class="form-group">
              <label>Nombre</label>
              <input type="text" class="form-control" v-model="dolencia.nombre" />
            </div>
            <div class="form-group">
              <label>Descripción</label>
              <input type="text" class="form-control" v-model="dolencia.descripcion" />
            </div>
            <button type="submit" class="btn btn-success">Editar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dolencia: {},
    };
  },
  created() {
    this.axios
      .get(`/api/dolencias/${this.$route.params.id}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then((response) => {
        this.dolencia = response.data.dolencia;
      });
  },
  methods: {
    updateDolencia() {
      this.axios
        .patch(
          `/api/dolencias/${this.$route.params.id}`,
          this.dolencia, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
          }
        }
        )
        .then((res) => {
          this.$router.go(0);
        });
      Swal.fire({
        icon: 'success',
        title: 'Dolencia Actualizada!',
        showConfirmButton: false,
        timer: 1500
      })
    },

  },
};
</script>