
<template>
<div class="container">
  <div class="row px-3 aqui">
    <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
      <div class="card-body">
        <router-link to="/colillas" class="btn btn-info pull-right" id="volver" ><i class="fas fa-arrow-alt-circle-left"></i></router-link>
       <h3 class="title text-center mt-4">Colillas</h3>
      <div class="modal-body">
      <form @submit.prevent="addColillas">
        <div class="row">
          <div class="form-group1">
            <label>Presupuesto</label>
            <select  id='presupuesto' class="form-control" v-model="colilla.presupuesto" >
            <option id="datos" v-for=" presupuesto in presupuestos" :key="presupuesto.id" :value="presupuesto.id" v-text="presupuesto.nombre" @click="cargarMontoPresupuesto(presupuesto.id) ,cargarRubro(presupuesto.id)">
              {{presupuesto.nombre}}
            </option>
            </select>
          </div>
          <div class="form-group1" >
          <label> Presupuesto Disponible</label>
          <input
            placeholder="₡"
            type="text"
            class="form-control"
            id="monto"
            name="monto"
            disabled
          />
          </div>
          <div class="form-group1">
            <label>Monto Disponible de Rubro</label>
            <input
             placeholder="₡"
            type="text"
            class="form-control"
            id="rubro"
            name="rubro"
            disabled
          />
          </div>
        </div>
        <hr>
      <div class="row">
        <div class="form-group1">
            <label>Colaborador</label>
            <select  id='presupuesto' class="form-control" v-model="colilla.colaborador">
            <option id="datos" v-for=" colaborador in colaboradores" :key="colaborador.id" :value="colaborador.id" v-text="colaborador.nombre">
              {{colaborador.nombre}}
            </option>
            </select>
          </div>
      <div class="form-group1">
        <label>Salario</label>
        <input
        type="text"
        placeholder="₡"
          id="salario"
          class="form-control"
          @input="aumentos()"
          v-model="colilla.salario"
        />
      </div>
      <div class="form-group1">
        <label>Periodo</label>
        <input
          type="text"
          class="form-control"
          v-model="colilla.periodo"
          />
      </div>
       </div>
       <hr>
       <div class="row">
      <div class="form-group1">
        <label>Horas Extra</label>
        <input
        type="number"
        id="horasExtra"
          class="form-control"
           @input="aumentos()"
          v-model="colilla.horasExtra"
        />
      </div>
      <div class="form-group1">
        <label>Feriados</label>
        <input
          type="number"
          id="feriados"
          class="form-control"
           @input="aumentos()"
          v-model="colilla.feriados"
          />
      </div>
      <div class="form-group1">
        <label>Vacaciones</label>
        <input
          type="number"
          id="vacaciones"
          class="form-control"
           @input="aumentos()"
          v-model="colilla.vacaciones"
          />
      </div>
       </div>
         <hr>
       <div class="row">
         <div class="form-group1">
        <label>Otros</label>
        <input
        type="text"
         placeholder="₡"
         id="otros"
          class="form-control"
          @input="aumentos()"
          v-model="colilla.otros"
        />
      </div>
        <div class="form-group1">
        <label>Número Factura</label>
        <input
          type="text"
          class="form-control"
          v-model="colilla.numero_Factura"
          />
      </div>
      <div class="form-group1">
            <label>Estado</label>
            <select class="form-control" v-model="colilla.estado">
            <option value="Pendiente">Pendiente</option>
            <option value="Pagado">Pagado</option>
            <option value="Anulado">Anulado</option>
            </select>
          </div>
      </div>
      <hr>
      <h3 class="title text-center mt-4">Deducciones</h3>
      <div class="row">
      <div class="form-group1">
        <label>CCSS</label>
        <input
        type="text"
        placeholder="₡"
        id="ccss"
        class="form-control"
        @input="cargarDeducciones()"
        disabled
        />
      </div>
      <div style="margin-left: 20px;" class="form-group1">
        <label>Otros</label>
        <input
        type="text"
         placeholder="₡"
          class="form-control"
          id="otrosDeducciones"
          @input="cargarDeducciones()"
          v-model="colilla.otrosDeducciones"
        />
      </div>
      <div class="form-group1">
        <label>Total Deducciones</label>
        <input
        type="text"
         placeholder="₡"
        id="deducciones"
        class="form-control"
        disabled
        />
      </div>

      </div>
      <hr>
      <div class="row">
      <div class="form-group1">
        <label>Total Bruto</label>
        <input
        type="text"
        placeholder="₡"
          id="totalBruto"
          class="form-control"
          disabled
        />
      </div>
      <div class="form-group1">
        <label>Total a pagar</label>
        <input
        type="text"
        placeholder="₡"
          id="total"
          class="form-control"
          disabled
        />
      </div>
      <div class="form-group1">
        <label>Observaciones</label>
        <input
        type="text"
          class="form-control"
          v-model="colilla.observaciones"
        />
      </div>
      </div>

      <button  type="submit" class="btn btn-success">Agregar</button>
    </form>
      </div>
  </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  inject:['reload'],
  data() {
    return {
      colillas: [],
      colilla:{'ccss': '' ,'totalDeducciones':'','totalBruto':'','neto':''},
      colaboradores:[],
      presupuestos:[],
      rubros:[],
    };
  },
  created() {
    this.axios.get("/api/colillas", {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
      .then((response) => {
      this.colillas = response.data.colillas;
      this.colaboradores = response.data.colaboradores;
      this.presupuestos = response.data.presupuestos;
      this.rubros= response.data.rubros;

    });
  },
   methods: {
    addColillas() {
      this.colilla.ccss= parseFloat(document.getElementById('ccss').value);
      this.colilla.totalDeducciones = parseFloat(document.getElementById('deducciones').value);
      this.colilla.totalBruto= parseFloat(document.getElementById('totalBruto').value);
      this.colilla.neto = document.getElementById('total').value;
      this.axios
        .post("/api/colillas", this.colilla, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        .then((response) => {
          var resultado = response.data.resultado['resultado'];
          var x = parseInt(resultado[0])===parseInt(1);
          if(x)
          {
            Swal.fire({
            title: 'No se puede Crear',
            text: 'El rubro no cuenta con el monto suficiente',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500
            })
          }
          else{
            Swal.fire({
            icon: 'success',
            title: 'Colilla Creada!',
            showConfirmButton: false,
            timer: 1500
            })
            this.reload();
          }
        });
    },
    cargarMontoPresupuesto(id){
      var nombres = '';
       for(var key in this.presupuestos){
         for(var i in this.presupuestos[key]){
          if(this.presupuestos[key].id == id){
            nombres = this.presupuestos[key].monto;
          }
         }
       }
       return $("#monto").val(nombres);

    },
    cargarDeducciones(){
      var monto = '';
      try {
        var ccss = parseFloat(document.getElementById('ccss').value)|| 0;
        var otros = parseFloat(document.getElementById('otrosDeducciones').value)|| 0;
        monto = otros + ccss;
      } catch (e) {

      }
      return $("#deducciones").val(parseFloat(monto).toFixed(2));
    },
    aumentos(){
      var monto = '';
      try {
        var horas =parseFloat(document.getElementById('horasExtra').value) * 4266.30 || 0;
        var vacaciones = parseFloat(document.getElementById('vacaciones').value) * 22753.57 || 0;
        var feriados=parseFloat(document.getElementById('feriados').value) * 22753.57 || 0;
        var otros=parseFloat(document.getElementById('otros').value)|| 0;
        var salario=parseFloat(document.getElementById('salario').value)|| 0;
        monto = otros + vacaciones +feriados +horas +salario;
        this.CCSS(monto);
        this.cargarDeducciones();
        this.deducciones(monto);
      } catch (e) {

      }
      return $("#totalBruto").val(parseFloat(monto).toFixed(2));

    },
    CCSS(valor){
      var monto = '';
      try {
        monto = valor * parseFloat(0.105);

      } catch (e) {

      }
      return $("#ccss").val(parseFloat(monto).toFixed(2));

    },
    cargarRubro(id){
      var nombres = '';
       for(var key in this.rubros){
         for(var i in this.rubros[key]){
          if(this.rubros[key].ID_Presupuesto == id && this.rubros[key].nombre_rubro == 'Salario'){
            nombres = this.rubros[key].monto_rubro;
          }
        }
       }
       return $("#rubro").val(nombres);
    },
    deducciones(totalBruto){
      var monto = '';
      try {
        var totalDeducciones =parseFloat(document.getElementById('deducciones').value) || 0;
        monto = totalBruto - totalDeducciones;
      } catch (e) {

      }
      return $("#total").val(parseFloat(monto).toFixed(2));


    }
   }
};
</script>