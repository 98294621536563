<template>
    <div class="container">
        <div class="row px-3 aqui">
            <div class="col-lg-10 col-xl-9 card flex-row mx-auto">
                <div class="card-body">
                    <a
                        @click="$router.push({name: 'citas', params: {id: $route.params.id}})"
                        class="btn btn-info pull-right"
                        id="volver"
                        ><i class="fas fa-arrow-alt-circle-left"></i
                    ></a>
                    <h3 class="title text-center mt-4">Citas</h3>
                    <form @submit.prevent="addCita">
                        <div class="form-group">
                            <label>Fecha</label>
                            <input
                                type="date"
                                class="form-control"
                                v-model="cita.fecha"
                            />
                        </div>
                        <div class="form-group">
                            <label>Nombre del especialista</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="cita.nombre"
                            />
                        </div>
                        <div class="form-group">
                            <label>Especialidad</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="cita.especialista"
                            />
                        </div>
                        <div class="form-group">
                            <label>Detalle</label>
                            <textarea v-model="cita.detalle" class="form-control">
                            </textarea>
                        </div>
                        <div class="form-group">
                            <label>Anexo</label>
                            <input
                                type="file"
                                class="form-control"
                                @change="obtenerImagen"
                            />
                        </div>
                        <button type="submit" class="btn btn-success">
                            Agregar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            citas: [],
            joven: {},
            cita: { joven: "" },
            imagen: {
                nombre: "",
                stock: 0,
                archivo: "",
            },
        };
    },
    created() {
        this.axios
            .get(`/api/jovenes/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            .then((response) => {
                this.joven = response.data.joven;
            });
    },
    methods: {
        obtenerImagen(event) {
            let file = event.target.files[0];
            this.imagen.archivo = file;
        },
        addCita() {
            let formData = new FormData();
            formData.append("foto", this.imagen.archivo);
            formData.append("nombre", this.cita.nombre);
            formData.append("fecha", this.cita.fecha);
            formData.append("especialista", this.cita.especialista);
            formData.append("detalle", this.cita.detalle);
            formData.append("joven", this.joven.id);
            this.axios
                .post("/api/citas", formData, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            })
                .then((response) =>  {
                    Swal.fire({
                        icon: "success",
                        title: "Cita Creada!",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then((alertResult) => {
                        this.$router.push({name: 'citas', params: {id: this.joven.id}});
                    });
                })
                .catch((err) => console.log(err))
                .finally(() => (this.loading = false));
        },
    },
};
</script>
