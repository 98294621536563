<template>
  <nav aria-label="Page pagination" class="list-pagination" >
      <ul class="pagination justify-content-center" >
          <li class="page-item" v-on:click="getPreviousPage()">
              <a class="page-link" href="#"> <i class="fa-solid fa-arrow-left"></i></a>
          </li>
          <li v-for="pagina in totalPaginas()" v-on:click="getDataPagina(pagina)" class="page-item"
              v-bind:class="{ active: isActive(pagina) }">
              <a class="page-link" href="#">{{ pagina }}</a>
          </li>
          <li class="page-item" v-on:click="getNextPage()">
              <a class="page-link" href="#"><i class="fa-solid fa-arrow-right"></i></a>
          </li>
      </ul>
  </nav>
</template>

<script>
export default {
  props: {
    listLength: Number,
    paginaActual: Number,
    getPreviousPage: Function,
    totalPaginas: Function,
    getDataPagina: Function,
    getNextPage: Function,
    isActive: Function
  },
};
</script>